@import "styles/_variables.scss";

.holidaysComponent {
	padding-top: 68px !important;
	padding-right: 26px !important;
	height: calc(100vh - 60.3px) !important;
	.employeeContainer {
		display: flex;

		.employeesTable {
			top: 240px;
			left: 167px;
			width: 334px;
			background-color: transparent;
			opacity: 1;

			.scrollContainer {
				overflow-x: auto;
				height: calc(100vh - 110px - 115px);
			}

			.employeeName {
				font-weight: 500;
				font-size: 22px;
				text-align: center;
				color: #261f63;
				cursor: pointer;
				
				white-space: nowrap;
				padding-right: 5px;
			}

			.employeeDaysLeft {
				font-weight: 500;
				font-size: 22px;
				color: #89c732;
				text-align: center;
			}

			.search {
				.searchInputContainer {
					padding-right: 26px !important;
					border-radius: 10px !important;
					background: #ffffff;
					opacity: 0.5;
				}
			}

			.employeeNameBox {
				padding: 19px 20px;
				display: flex;
				justify-content: space-between;
				border-bottom: 3px solid #f0f0f0;
			}
		}

		.calendarContainer {
			margin-left: 23px;
			padding: 0px 30px 25px;
			flex: 1;
			background-color: transparent;
			box-shadow: -1px -1px 20px #2c28281c;
			opacity: 1;
			border-radius: 5px;
			min-width: 500px;

			.deleteVacationMessage {
				font-size: 25px;
				font-family: "Roboto";
				font-weight: 500;
				color: #261f63;
				margin-bottom: 20px;
			}

			.modalContainer {
				max-height: 469px;

				.vacationsAppendedModal {
					border-bottom: 1px solid #c7c6d6;
					padding-bottom: 2rem;

					&:last-child {
						&:not(.red) {
							padding-bottom: 0;
						}
						border-bottom: none;
					}
					.saveButton {
						display: flex;
						justify-content: flex-end;
						margin-top: 25px;
						button {
							margin-right: 0;
						}
					}
				}

				.modalContainerInner {
					display: flex;
					justify-content: space-between;

					.employeeNameModal {
						font-weight: bold;
						color: #261f63;
						font-size: 23px;
						padding-left: 0px;
						padding-right: 150px;
					}

					.addVacationButton {
						width: 160px;
						height: 38px;
						padding: 6px 25px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						border-radius: 5px;
						min-width: 140px;
						color: white;
						background-color: #261f63;
						font-weight: 500;
						cursor: pointer;
						margin-left: 50px;
					}
				}

				.vacationTypeEdit {
					margin-top: 26px;
					justify-content: space-between;

					.pencilIcon {
						display: flex;
						margin-left: 240px;
						:first-child {
							margin-right: 0.3rem;
						}

						svg {
							width: 20px;
							height: 20px;
							cursor: pointer;
							color: $_red;
						}
					}

					.vacationTypeInner {
						font-size: 18px;
						color: #261f63;
					}

					.vacationTypeName {
						font-weight: bold;
						font-size: 18px;

						.annualLeave {
							color: #2dce98;
							margin-left: 10px !important;
						}

						.maternityLeave {
							margin-left: 10px;
							color: #11cdef !important;
						}

						.specialLeave {
							margin-left: 10px;
							color: #d52d0f !important;
						}

						.medicalLeave {
							margin-left: 10px;
							color: #feb969 !important;
						}
					}
				}

				.vacationType {
					margin-top: 26px;
					display: flex;
					justify-content: space-between;

					.pencilIcon {
						display: flex;
						margin-left: 240px;
						:first-child {
							margin-right: 0.3rem;
						}

						svg {
							width: 20px;
							height: 20px;
							cursor: pointer;
							color: $_red;
						}
					}

					.vacationTypeInner {
						font-size: 18px;
						color: #261f63;
					}

					.vacationTypeName {
						font-weight: bold;
						font-size: 18px;

						.annualLeave {
							color: #2dce98;
							margin-left: 10px !important;
						}

						.maternityLeave {
							margin-left: 10px;
							color: #11cdef !important;
						}

						.specialLeave {
							margin-left: 10px;
							color: #d52d0f !important;
						}

						.medicalLeave {
							margin-left: 10px;
							color: #feb969 !important;
						}
					}
				}

				.employeeEmailModal {
					font-size: 18px;
					color: #261f63;
					margin-top: 25px;
				}

				.employeeTenantNameModal {
					margin-top: 11px;
					font-size: 18px;
					color: #261f63;
					font-weight: bold;
					padding-bottom: 26px;
					border-bottom: 1px solid #c7c6d6;

					&:last-child {
						border-bottom: none;
					}
				}

				.fromToContainer {
					margin-top: 20px;
					display: flex;
					justify-content: space-between;

					.from {
						font-size: 18px;
						color: #261f63;
						font-weight: bold;
					}

					.to {
						font-size: 18px;
						color: #261f63;
						font-weight: bold;
						margin-right: 208px;
					}
				}

				.leftDaysContainer {
					font-size: 17px;
					color: #d52d0f;
				}
			}

			.vacationContainer {
				.blue {
					color: blue !important;
				}

				.employeeNameModal {
					font-weight: bold;
					color: #261f63;
					font-size: 23px;
					padding-left: 0px;
					padding-bottom: 36px;
					border-bottom: 1px solid #c7c6d6;
				}

				.typeName {
					margin-top: 17px;
					font-size: 18px;
					color: #261f63;
					font-weight: 700;
				}

				.vacationTypeDropdownEdit {
					margin-top: 13px;
					height: 42px;
					padding: 6px 35px;
					display: flex;
					align-items: center;
					justify-content: center;
					border: 3px solid #c1e095;
					border-radius: 10px;
					min-width: 140px;
					background-color: #fbfbfb;
					font-weight: 500;
					cursor: pointer;
					font-size: 18px;
					width: 660px;

					option {
						color: red;
						font-size: 20px;
					}

					.annualLeave {
						color: #2dce98;
						margin-left: 10px !important;
					}

					.maternityLeave {
						margin-left: 10px;
						color: #11cdef !important;
					}

					.specialLeave {
						margin-left: 10px;
						color: #d52d0f !important;
					}

					.medicalLeave {
						margin-left: 10px;
						color: #feb969 !important;
					}
				}

				.vacationTypeDropdown {
					margin-top: 13px;
					width: 660px;
					height: 42px;
					padding: 6px 35px;
					display: flex;
					align-items: center;
					justify-content: center;
					border: 3px solid #c1e095;
					border-radius: 10px;
					min-width: 140px;
					background-color: #fbfbfb;
					font-weight: 500;
					cursor: pointer;
					font-size: 18px;

					option {
						color: red;
						font-size: 20px;
					}

					.annualLeave {
						color: #2dce98;
						margin-left: 10px !important;
					}

					.maternityLeave {
						margin-left: 10px;
						color: #11cdef !important;
					}

					.specialLeave {
						margin-left: 10px;
						color: #d52d0f !important;
					}

					.medicalLeave {
						margin-left: 10px;
						color: #feb969 !important;
					}
				}

				.leftDaysContainer {
					margin-top: 35px;
					font-size: 18px;
					color: #d52d0f;
					margin-bottom: 56px;
				}
			}

			.calendarHeader {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-top: 20px;

				.calendarText {
					font-size: 26px;
					font-weight: 500;
					color: #1f1960;
				}

				.buttonHeader {
					display: flex;
					justify-content: space-between;

					.addVacationButton {
						margin-right: 20px;
						width: 160px;
						height: 38px;
						padding: 6px 25px;
						display: flex;
						align-items: center;
						justify-content: space-between;
						border-radius: 5px;
						min-width: 140px;
						color: white;
						background-color: #261f63;
						font-weight: 500;
						cursor: pointer;
					}

					.calendarType {
						margin-right: 20px;
						width: 312px;
						height: 38px;
						display: flex;
						justify-content: center;
						font-weight: 500;
						border-radius: 5px;

						div {
							border: 1px solid #8898aa;
							padding: 6px 10px;
							border-radius: 1px;
							width: 78px;
							display: flex;
							justify-content: center;

							span {
								padding: 2px;
								font-weight: 500;
								color: #261f63;
								cursor: pointer;
							}
						}

						.filterRow {
							display: flex;
							font-size: 18px;
							margin-bottom: 1.5rem;
							position: relative;
						}
					}
				}
			}

			.calendar {
				height: calc(100vh - 110px - 100px);
			}
		}
	}

	.fromToContainer {
		margin-top: 75px;
		display: flex;
		justify-content: space-between;

		.from {
			font-size: 18px;
			color: #261f63;
			font-weight: bold;
		}

		.to {
			font-size: 18px;
			color: #261f63;
			font-weight: bold;
			margin-right: 207px;
		}
	}

	.fromToBoxContainer {
		margin-top: 15px;
		display: flex;
		justify-content: space-between;

		.fromBoxContainer {
			input {
				width: 244px;
				height: 42px;
				padding: 6px 35px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 3px solid #c1e095;
				border-radius: 10px;
				min-width: 140px;
				background-color: #fbfbfb;
				font-weight: 500;
				cursor: pointer;
				font-size: 18px;
			}

			.calendarIcon {
				color: #89c732;
				width: 25px;
				height: 25px;
				display: flex;
				align-items: center;
				margin-left: 195px;
				cursor: pointer;
			}
		}

		.toBoxContainer {
			input {
				width: 244px;
				height: 42px;
				padding: 6px 35px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 3px solid #c1e095;
				border-radius: 8px;
				min-width: 140px;
				background-color: #fbfbfb;
				font-weight: 500;
				cursor: pointer;
				font-size: 18px;
				margin-left: 30px;
			}

			.calendarIcon {
				color: #89c732;
				width: 25px;
				height: 25px;
				display: flex;
				align-items: center;
				margin-left: 195px;
				cursor: pointer;
			}
		}
	}

	.leftDaysContainer {
		margin-top: 10px;
		font-size: 18px;
		color: #d52d0f;
	}
}

.vacationModalType {
	display: flex;
}

.annualLeave {
	color: #2dce98;
	margin-left: 10px !important;
}

.maternityLeave {
	margin-left: 10px;
	color: #11cdef !important;
}

.specialLeave {
	margin-left: 10px;
	color: #d52d0f !important;
}

.medicalLeave {
	margin-left: 10px;
	color: #feb969 !important;
}

.specialDays{
	margin-top: 10px;
	font-size: 18px;
	color: #d52d0f;
	font-weight: 500;
}

.annualDays{
	margin-top: 10px;
	font-size: 18px;
	color: #2dce98;
	font-weight: 500;
}

.medicalDays{
	margin-top: 10px;
	font-size: 18px;
	color: #feb969;
	font-weight: 500;
}

.maternityDays{
	margin-top: 10px;
	font-size: 18px;
	color: #11cdef;
	font-weight: 500;
}