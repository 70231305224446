.iconWrapper {
    cursor: pointer;
    margin: 15px 10px;
    display: flex;
    justify-content: center;
    height: fit-content;
    svg {
        height: 20px;
        width: 20px;
    }
}