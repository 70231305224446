@import "styles/_variables.scss";

.navBar {
	height: $_navBar-height;
	background-color: $_bg-header;
	z-index: 1041;
	&.dropDownShown {
		background-color: white;
		.iconsContainer,
		.selectContainer {
			background-color: white;
		}
	}
	color: white;
	display: flex;
	align-items: center;
	.iconsContainer {
		background-color: $_bg-header;
		padding: 10px 0;
		display: flex;
		align-items: center;
		height: 100%;
		& > div {
			padding: 0 10px;
		}
		svg {
			width: 25px;
			height: 25px;
		}

		.dropdownMenu {
			min-width: 100px;
			transform: translate(-50%, 10px);
			left: 50%;
			opacity: 0;
			transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s ease;

			&.active {
				opacity: 1;
				transform: translate(-50%, 60px);
			}
		}
		.languageMenu {
			min-width: 60px;
		}
		.languageItem {
			display: flex;
			justify-content: center;
		}
	}
	.navIcon {
		width: fit-content;
		max-width: fit-content;
	}

	.notificationsContent {
		display: flex;
		min-width: 200px;
		min-height: 200px;
		flex-direction: column;
		color: $_blue-dark;
		.header {
			font-size: 18px;
			font-weight: 500;
			padding: 15px;
			display: flex;
			justify-content: center;
		}
		.body {
			flex: 1;
			background-color: #fafafa;
			min-width: 400px;
			max-height: 300px;
			overflow-y: scroll;
		}
		.item {
			border-bottom: 1px solid #f0f0f0;
			padding: 13px 47px;
			&:hover {
				background-color: #fff;
			}
		}
		.title {
			font-size: 13px;
			font-weight: bold;
		}
		.subTitle {
			font-size: 11px;
			opacity: 0.5;
			padding-top: 5px;
		}

		.footer {
			display: flex;
			justify-content: center;
			padding: 20px;
			color: $_green-light;
			font-size: 12px;
			font-weight: 500;
			user-select: none;
		}

		.notificationSeeMore{
			cursor: pointer;
			&:hover{
				background-color: #d3d3d336;
			}
		}
	}
}

.modalPayroll {
	min-width: 400px;
	padding-top: 0;
}

.title {
	margin-top: 3px;
	font-size: 20px;
	font-weight: 700;
	color: #261F63;
}

.nameBox {
	display: flex;
	justify-content: space-between;

	border-bottom: 1px solid lightgrey;

	.name {
		margin-top: 20px;
		font-size: 18px;
		font-weight: 500;
		color: $_green-light;
	}

	.tenantName {
		margin-top: 20px;
		font-size: 20px;
		margin-left: 15px;
		font-weight: 500;
		color: #261F63;
	}
}


.emailBox {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 1.5rem;
	border-bottom: 1px solid lightgrey;


	.email {
		font-size: 18px;
		font-weight: 500;
		color: $_green-light;
	}

	.emailAddress {
		font-size: 20px;
		margin-left: 15px;
		font-weight: 500;
		color: #261F63;
	}
}

.noNotificationsContainer{
	display: flex;
	justify-content: center;

	img {
		width: 50%;
		height: 50%;
	}
}

