@import "styles/_variables.scss";

.table {
	width: 100%;
	border-collapse: collapse;
	color: $_textColor-global;
}

.tableHead:not(.noPosition) {
	border-radius: 10px;
	.headerRow {
		width: calc(100% - 13px);
		:first-child {
			border-top-left-radius: 10px;
			border-bottom-left-radius: 10px;
		}
		:last-child {
			border-top-right-radius: 10px;
			border-bottom-right-radius: 10px;
		}
		&:nth-child(1) {
			.headerCell {
				position: sticky;
				top: 0;
			}
		}
		&:nth-child(2) {
			.headerCell {
				position: sticky;
				top: 33px;
				padding-top: 5px;
				padding-bottom: 9px;
				height: 42px;
			}
		}
		&:nth-child(3) {
			.headerCell {
				position: sticky;
				top: calc(33px + 42px);
			}
		}
	}
}

.tableHead,
.tableBody {
	.rootCell {
		padding: 0.75rem 1.25rem;
		font-weight: 500;
		text-align: left;
		font-size: 19px;
		&.pl0 {
			padding-left: 0 !important;
		}
		&.pr0 {
			padding-right: 0 !important;
		}
	}
	.center {
		text-align: center;
	}

	label {
		justify-content: center;
	}
}
.headerCell {
	background-color: #edecf0;
	z-index: 1;
	&.noBackground {
		background-color: #fff;
	}
	&.noRadius {
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
	}
	&.noRadiusBottom {
		border-bottom-left-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
	&.noRadiusAll {
		border-top-left-radius: 0 !important;
		border-bottom-left-radius: 0 !important;
		border-top-right-radius: 0 !important;
		border-bottom-right-radius: 0 !important;
	}
}

.headerCell,
.bodyCell {
	padding: 0.75rem 1.25rem;
	font-weight: 500;
	text-align: left;
	font-size: 19px;
	img {
		font-weight: normal;
		font-size: 14px;
	}
}
.tableBody {
	.tableRow {
		.bodyCell {
			padding: 0.75rem 1.25rem;
			font-weight: 400;
			&.bold {
				font-weight: 500;
			}
			&.iconCell {
				svg {
					color: $_blue-dark;
					width: 25px;
					height: 25px;
				}
			}
			.docIconWrapper {
				width: fit-content;
				margin: auto;
			}
		}
		&:not(:last-child) {
			border-bottom: 2px solid #f0f0f0;
		}
	}

	.userNameCell {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		img,
		.image {
			width: 50px;
		}

		.nameContainer {
			margin-left: 1rem;
			cursor: pointer;
		}

		.imageWrapper {
			width: 50px;
			min-width: 50px;
			height: 50px;
			background: white;
			border-radius: 50%;
			display: flex;
			align-items: center;
			justify-content: center;
			overflow: hidden;
		}
	}
}

.previousSvg {
	width: 20px;
	height: 20px;
}
.nextSvg {
	width: 20px;
	height: 20px;
}

.tableWrapper {
	height: calc(100vh - 373px);
	min-height: calc(100vh - 373px);
	overflow: auto;
}

.paginationWrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	padding: 1rem 0;
	font-size: 17px;

	.paginationSelect {
		border-radius: 4px;
	}
}
