.numberCard {
    .title {
        font-size: 15px;
    }
    .number {
        padding-top: 10px;
        font-size: 25px;
        font-weight: 400;
    }
}
