@import "styles/_variables.scss";

.reportsComponent {
	.header {
		display: flex;
		margin-top: 33px;

		.arrowIcon {
			margin-top: 7px;
			margin-right: 30px;
			width: 19px;
			height: 19px;
			color: #89c732;
			cursor: pointer;
		}
	}

	.reportsInner {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		margin-top: 50px;
	}
}
.sectionHeader {
	font-weight: 500;
	width: 100%;
	color: $_blue-dark;
	display: flex;
	padding: 0 0 1.5rem 0;
	&.columned {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.title {
		font-size: 24px;
		color: $_blue-dark;
		display: flex;
		flex-direction: row;
		&:not(.second) {
			min-width: 400px;
		}
		&.second {
			width: 80%;
		}
	}

	.point {
		color: $_green-light;
		padding-right: 1rem;
	}
}

.modal {
	min-width: 950px !important;
	width: 1200px !important;
}
.contentClass {
	overflow: initial !important;
}

.modalHeader {
	padding-bottom: calc(1.5rem - 5px) !important;
	.icon {
		margin-left: 1rem;
		cursor: pointer;
		display: flex;
		svg {
			width: 22px;
			height: 22px;
		}
	}

	.buttonContainer {
		display: flex;
		justify-content: flex-end;
		align-items: flex-start;
	}

	.cell {
		display: flex;
		align-items: center;
		min-width: fit-content;
		font-weight: 500;
		color: $_blue-dark;
		cursor: pointer;
		white-space: nowrap;
		user-select: none;
		margin-left: 0.5rem;
		font-size: 18px;

		.icon {
			display: flex;
			align-items: center;
			color: $_green-light;
			margin-right: 0.5rem;
			svg {
				stroke: $_green-light;
				width: 22px;
				height: 22px;
			}
		}

		.emailIcon {
			transform: rotate(-90deg);
		}

		.loader {
			height: 20px;
			width: 20px;
		}
	}

	.dropdownText {
		padding-right: 5px;
	}
	.menuClass {
		top: 25px;
	}
	.emailHeaderCell {
		min-width: 159px;
		display: flex;
		justify-content: center;
	}
}

.inputsContainer {
	width: 95%;
	margin: auto;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 1em;
	column-gap: 1em;
	padding: 2rem;
	.input {
		flex: 1 1 30%;

		padding: 0 1rem 1rem 1rem;
		min-height: 106px;
		font-size: 16px;
		color: #261f63;
	}

	.inputClass {
		min-height: 57px;
		display: flex;
		align-items: center;
		width: 100%;
	}
}

.employeeInput {
	.desc {
		font-size: 1em;
		color: #261f63;
		font-weight: 500;
		padding-bottom: 0.5rem;
	}
}

.agreementRow {
	display: flex;
	align-items: center;
	.agreementText {
		color: $_red;
		padding-left: 0.75rem;
	}

	.checkbox {
		cursor: pointer;
		width: 24px !important;
		height: 24px !important;
		border-width: 1px !important;
		border-radius: 50%;
		overflow: hidden;
	}

	.checkboxIconContainer {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		align-self: stretch;
		background-color: #89c732;
	}
}

.dateCell {
	color: $_blue-dark;
	opacity: 0.5;
	font-size: 19px;
	font-weight: 500;
	margin-left: 1.5rem;
	white-space: nowrap;
}

.headerDropdonw {
	height: unset;
}

.vacationListReport {
	padding: 0;
	.tableWrapper {
		width: 100%;
		border-radius: 0;
		th:not(.nameCell),
		td:not(.nameCell) {
			text-align: center;
			color: #261f63;
		}
		.nameCell {
			padding-left: 8px;
		}
		thead th {
			padding: 8px 1.5rem;
			padding-top: 8px !important;
			padding-bottom: 8px !important;
			border-radius: 0;
			position: unset;
			height: unset;
			max-height: unset;
			background-color: #F2F1F5;
			color: #261f63;
			font-size: 18px;
			top: 0 !important;
		}

		thead tr:nth-child(2) th {
			position: unset;
			top: unset;
			padding-top: 12px;
			padding-bottom: 12px;
			height: unset;
		}
	}

	.filterRow {
		display: flex;
		font-size: 1.125rem;
		margin-bottom: 30px;
		position: relative;
	}
	.filterDescription {
		flex: 1;
		display: flex;
		justify-content: flex-end;
		color: #261f6399;
		padding-right: 14px;
		font-weight: 500;
	}
	.filterButtons {
		height: 37px;
		display: flex;
		border-radius: 10px;
		overflow: hidden;
		font-size: 1.125rem;
		font-weight: 500;
		min-width: 250px;
		z-index: 1041;
		border: 1px solid #89c732;

		.button {
			background-color: #fff;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-right: 2rem;
			padding-left: 2rem;
			height: 100%;
			cursor: pointer;
			user-select: none;
			flex: 1 1 50%;
			width: 50%;
			min-width: fit-content;

			&:first-of-type {
				border-right: 1px solid $_green-light;
			}
			&:last-of-type {
				padding-right: 0;
				padding-left: 1.5rem;
				justify-content: space-between;
			}
		}

		.fadeText {
			opacity: 0.8;
		}

		.calendar {
			display: flex;
			padding: 5px 10px;
			margin-left: 1rem;
			background-color: $_green-light;
			opacity: 0.5;
			height: 100%;
			display: flex;
			align-items: center;
			color: white;
		}
	}
	.filterContent {
		border: 0.5px solid $_green-light;
		border-radius: 4px;
		background-color: #fff;
		display: flex;
		position: absolute;
		top: 48px;
		right: 0;
		overflow: hidden;
		min-width: 250px;
		z-index: 1041;
		transition: all 0.2s ease;

		.months {
			min-height: fit-content;
		}

		.months,
		.years {
			display: flex;
			flex-direction: column;
			height: 100%;
			min-height: 100%;
			overflow-y: auto;
			width: 50%;
		}
		.months {
			border-right: 1px solid $_green-light;
		}
		.periodItem {
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 1.125rem;
			font-weight: 500;
			opacity: 0.5;
			padding: 0.75rem 1.5rem;
			cursor: pointer;
			user-select: none;
			&.selected,
			&:hover {
				opacity: 0.9;
				background-color: #eff5e6;
			}
		}
	}

	.tableTitle {
		color: $_blue-dark;
		font-weight: 500;
		font-size: 16px;
		text-align: left;
	}

	.tableSubtitle {
		font-size: 16px;
		font-weight: normal;
		text-align: left;
		color: #261f6399;
		&:empty {
			padding-top: 0;
		}
	}

	thead .nameCell {
		padding-bottom: 8px;
	}
	th.pr0,
	td.pr0 {
		padding-right: 0 !important;
	}
}

.validateEmail{
	padding-left: 10px;
  }

  .emailWrapper{
	  display: flex;
	  justify-content: center;
	  margin: auto;
  }

  .sendEmailWrapper{
	  display: flex;
	  flex-direction: row;
	  justify-content: center;
	  overflow-y:unset !important;
	  width: 300px;
	  height: 130px;
  }


  .emailBox{
	display: flex;
	flex-direction: column;
	width: 90%;
	margin-top: 20px;
  
	input{
	  width: 100px;
	  background-color: #89C7321A;
	  border: 1px solid #1F196099;
	  padding: 0px 10px;
	  margin-right: 20px;
	  height: 42px;
	  border-radius: 5px;
	}
  }


  .fromToContainer {
	margin-top: 20px;
	display: flex;
	justify-content: space-between;

	.from {
		font-size: 18px;
		color: #261f63;
		font-weight: bold;
	}

	.to {
		font-size: 18px;
		color: #261f63;
		font-weight: bold;
		margin-right: 208px;
	}
}


.fromToBoxContainer {
	
	display: flex;
	width: 100%;
	justify-content: end;
	margin: auto;

	.fromBoxContainer {
		input {
			width: 244px;
			height: 42px;
			padding: 6px 35px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 3px solid #c1e095;
			border-radius: 10px;
			min-width: 140px;
			background-color: #fbfbfb;
			font-weight: 500;
			cursor: pointer;
			font-size: 18px;
		}

		.calendarIcon {
			color: #89c732;
			width: 25px;
			height: 25px;
			display: flex;
			align-items: center;
			margin-left: 195px;
			cursor: pointer;
		}
	}

	.toBoxContainer {
		input {
			width: 244px;
			height: 42px;
			padding: 6px 35px;
			display: flex;
			align-items: center;
			justify-content: center;
			border: 3px solid #c1e095;
			border-radius: 8px;
			min-width: 140px;
			background-color: #fbfbfb;
			font-weight: 500;
			cursor: pointer;
			font-size: 18px;
			margin-left: 30px;
		}

		.calendarIcon {
			color: #89c732;
			width: 25px;
			height: 25px;
			display: flex;
			align-items: center;
			margin-left: 195px;
			cursor: pointer;
		}
	}
}

.fromStyle{
	font-weight: 500;
	margin-left: 5px;
	margin-bottom: 5px;
}

.toStyle{
	font-weight: 500;
	margin-left: 35px;
	margin-bottom: 5px;
}