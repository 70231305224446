@import "styles/_variables.scss";

.pagination {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  margin: 0;
  padding: 0;
}

.pagination li {
  display: flex;
  border-radius: 4px;
  background: #ffffff;
  &:not(.pagination__link_mains:last-child) {
    margin: 0 0.5rem;
  }
  a {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    min-width: 50px;
  }
  &:not(.pagination__link_mains) a {
    color: #261f63;
    opacity: 0.5;
  }
  &:not(.pagination__link--disabled) a {
    cursor: pointer;
  }
  svg {
    color: #261f63;
  }
}

.pagination__link {
  font-weight: bold;
}

li.pagination__link--active {
  background: #eff5e6;
  opacity: 1;
}

li.pagination__link--disabled {
  color: rgb(198, 197, 202);
  svg * {
    color: rgb(198, 197, 202);
  }
}

.pagination__link_mains {
  display: flex;
  color: #1f1960;
  a {
    color: #1f1960;
    padding: 5px !important;
  }
  justify-content: center;
}
