@import "styles/_variables.scss";
@import "styles/_fonts.scss";

* {
  box-sizing: border-box;
  scrollbar-color: #89c732 #f0f0f0;
  font-family: "Roboto", sans-serif;
}

*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: #89c732;
  border-radius: 20px;
  border: 1px solid #f0f0f0;
}

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin: 0;
  background-color: #fff;
  overflow-x: auto;
  overflow-y: hidden;
  min-width: fit-content;
}

h1 {
  font-size: 2.5rem;
  color: #2c292b;
}

h2 {
  font-size: 1.5rem;
  color: #2c292b;
}

h1,
h2,
h3,
h4,
h5,
h6,
input {
  color: $_blue-dark;
}

.row {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;

  .col-half {
    flex: 1 1 50%;
  }
}

.center-h {
  display: flex;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
}

.spaceBetween {
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
}

.pointer {
  cursor: pointer;
}

.cursorAuto {
  cursor: auto !important;
}

.f14 {
  font-size: 14px;
}

.f15 {
  font-size: 15px;
}

.f-18 {
  font-size: 18px !important;
}

.f-22 {
  font-size: 22px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.mb-1 {
  margin-bottom: 10px !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-5 {
  padding-right: 5px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.noWrap {
  white-space: nowrap !important;
}

.lowZindex {
  z-index: 400 !important;
}

.Toastify {
  font-size: 16px;
}

.v-center {
  align-items: center !important;
}

.h-center {
  justify-content: center !important;
}

.h-end {
  display: flex;
  justify-content: flex-end !important;
}

.h-100 {
  height: 100%;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
}

.justify-start {
  display: flex;
  justify-content: flex-start !important;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

.w-fit-content {
  width: fit-content;
}

.mw-fit-content {
  min-width: fit-content !important;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.text-center {
  text-align: center !important;
}

.align-left {
  text-align: left;
}

.p-0 {
  padding: 0 !important;
}

.ph-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.relative {
  position: relative;
}

.v-top {
  display: flex;
  align-items: flex-start;
}

.color-green {
  color: $_green-light;
}

.d-none {
  display: none !important;
}

.noWrap {
  white-space: nowrap;
}

.customTooltip {
  border-radius: 6px !important;
  cursor: pointer;
}

.customTooltip2 {
  border-radius: 6px !important;
  cursor: pointer;
  width: 100px!important;
  z-index: 80000!important;
  // width: 80% !important;
}

.customTooltipQB {
  border-radius: 6px !important;
  cursor: pointer;
  width: 20% !important;
}

.tooltip3 {
  border-radius: 6px !important;
  cursor: pointer;
  width: 10% !important;
}

.customTooltip::before {
  border: 0 !important;
}

.customTooltip::after {
  border: 0 !important;
}

.noArrowsNumberInput input {
  border-radius: 0;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
}

.percentageInput {
  &:after {
    content: "%";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  input {
    padding-right: 10px !important;
  }
}

.lightButton {
  color: $_blue-dark;
  background-color: transparent;
  border: 1px solid transparent;
  box-shadow: none;
  padding-right: 5px;
  padding-left: 5px;
  margin-right: 1rem;
  margin-left: 0;

  &:hover {
    filter: drop-shadow(0 0.2rem 0.25rem rgba(0, 0, 0, 0.5));
  }
}

.fc-header-toolbar {
  position: relative;

  .fc-toolbar-chunk {
    &:first-child {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .fc-button-group {
        width: 600px;
        display: flex;
        justify-content: space-between;

        .fc-button.fc-button-primary {
          &:focus {
            box-shadow: none !important;
          }

          outline: none !important;
          border: none;
          color: #2d276a;
          background-color: transparent;
        }
      }
    }

    &:nth-child(2) {
      margin: 0 auto;
    }

    &:nth-child(3) {
      right: 0;
      top: -55px;
      position: absolute;

      .fc-button-group {
        .fc-button.fc-button-primary {
          background-color: white;
          border-color: #a7b2bf;
          border-width: 2px;
          color: #2d276a;
          font-weight: 500;
          text-transform: capitalize;

          &.fc-button-active {
            background-color: white;
            border-color: #a7b2bf;
            color: #8ac734;
          }
        }
      }
    }
  }
}

.react-responsive-modal-overlay {
  background-color: #43434333;
  box-shadow: none;
}

.react-responsive-modal-modal {
  box-shadow: none;
}

.validationText {
  display: none;
}

.inputInvalid {
  input {
    border-bottom: 1px solid #d52d0f !important;
  }

  .validationText {
    display: block;
    font-size: 55%;
    color: red;
    background: white;
    border-radius: 10px;
    padding: 1px 10px;
    white-space: nowrap;
  }
}

.asterix::after {
  content: "*";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  color: $_red;
}

img {
  object-fit: scale-down;
}

.Toastify {
  z-index: 1111111111;
  position: relative;

  .Toastify__toast-container {
    padding-bottom: 40px;
    width: fit-content;
  }

  .Toastify__toast {
    margin-bottom: 10px;
    color: #000000;
    align-items: center;
    border-radius: 25px;
    height: 90px;
    width: 390px;
    padding-left: 120px;
    position: relative;
    font-weight: 500;

    &.Toastify__toast--success {
      background-color: #F4FFF4;
      border: solid 1px #3AC32E;

      &::before {
        background-image: url("../assets/icons/successToastIcon.svg");
      }
    }

    &.Toastify__toast--error {
      background-color: #FFEFEF;
      border: solid 1px #FF0000;

      &::before {
        background-image: url("../assets/icons/errorToastIcon.svg");
      }
    }

    &.Toastify__toast--warning {
      background-color: #FFF8EF;
      border: solid 1px #FF9300;

      &::before {
        background-image: url("../assets/icons/warningToastIcon.svg");
      }
    }

    &.Toastify__toast--info {
      background-color: #EAF6FF;
      border: solid 1px #0061A8;

      &::before {
        background-image: url("../assets/icons/infoToastIcon.svg");
      }
    }

    &::before {
      content: '';
      width: 60px;
      height: 60px;
      position: absolute;
      left: 30px;
      background-size: 100%;
    }
  }

  .Toastify__close-button {
    background: none;
    margin: auto 5px;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    &:hover {
      background-color: #ffffff;
    }

    svg {
      fill: black;
      width: 20px;
      height: 20px;
    }
  }
}

.react-responsive-modal-overlay {
  background: rgba(0, 0, 0, 0.2)!important;
}

.error {
  background-color: red;
  color: white;
}