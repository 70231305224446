@import "styles/_variables.scss";

.sideBar {
  position: fixed;
  height: 100%;
  width: $sidebar-width-collapsed;
  transition: width $_seconds-global;
  background-color: $_bg-sideBar;
  z-index: 1000;

  .headerDiv {
    height: $_navBar-height;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .productLogo {
    height: 55px;
    width: $sidebar-width-collapsed;
    padding: 8px 15px;
    display: flex;
    justify-content: center;
    transition: width 0.1s;
    cursor: pointer;

    img {
      width: 117px;
      padding: 0;
      opacity: 0;
      max-width: 0;
      pointer-events: none;
      visibility: hidden;
      transition: opacity $_seconds-global;
    }

    .showIcon {
      opacity: 1;
      pointer-events: all;
      visibility: visible;
      max-width: 86%;
      transition: all 0.2s;
    }

    .showIcon.large {
      padding: 0 40px;
    }
  }

  .iconDiv {
    cursor: pointer;
    font-size: 25px;
    flex: 1 1 20%;
    display: flex;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
  }

  .contentDiv {
    color: $_textColor-global;
    background: $_bg-sideBar;
    box-sizing: border-box;
    padding: 0 15px;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
  }

  .menuItem {
    a {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 15px;
      font-weight: 400;
      text-decoration: none;
      width: 100%;
      margin-bottom: 2px;
      cursor: pointer;
      color: inherit;
      border-radius: 5px;
      transition: all $_seconds-global;
    }

    a:hover {
      background-color: #f6f9fc;
    }

    img {
      width: 25px;
    }

    svg {
      width: 25px;
      height: 25px;
    }
  }

  .menuIcon {
    padding: 22px 15px;
    display: flex;
    align-items: center;
    font-size: 25px;
    justify-content: flex-start;
    padding-left: 30px;
    transition: max-width $_seconds-global;
  }

  .itemActive {
    background-color: #f6f9fc;
  }

  .menuTitle {
    display: flex;
    white-space: nowrap;
    flex: 1;
    padding-left: 0px;
    font-size: 18px;
    opacity: 0;
    pointer-events: none;
    max-width: 0;
    font-size: 0;
    transition: all 0.2s;
  }

  &.open {
    width: $_sideBar-width;

    .menuTitle {
      pointer-events: all;
      opacity: 1;

      max-width: unset;
      font-size: 16px;
    }

    .itemActive {
      background-color: #f6f9fc;

      .menuTitle {
        font-weight: bold;
        opacity: 1;
      }
    }

    .productLogo {
      height: 75px;
      width: 100%;

      img.showIcon {
        padding: 0 15px;
        width: 90%;
        opacity: 1;
        pointer-events: all;
        visibility: visible;
        max-width: unset;
      }
    }

    .headerDiv {
      width: $_sideBar-width;
      padding: 15px 15px 15px 0;
    }
  }

  &.flat {
    width: calc(117px - 30px);

    .sidebarContentDiv {
      padding: 0;
    }
  }
}
