@import "styles/_variables.scss";

.react-tabs {
	-webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
	position: relative;
	padding: 0;
	margin: 3rem 0 2.5rem 0;
	&::after {
		content: "";
		position: absolute;
		height: 6px;
		width: 100%;
		background-color: #f0f0f0;
		bottom: -6px;
		left: 0;

		border-top-right-radius: 4px !important;
		border-bottom-right-radius: 4px !important;
		border-top-left-radius: 4px !important;
		border-bottom-left-radius: 4px !important;
	}

	white-space: nowrap;
}

.react-tabs__tab {
	display: inline-block;
	border-bottom: none;
	position: relative;
	list-style: none;
	padding: 6px 3rem 4px;
	font-size: 20px;
	text-align: center;
	cursor: pointer;
	transition: font-weight 0.025s ease;
	white-space: nowrap;

	&::before {
		display: block;
		content: attr(title);
		font-weight: bold;
		height: 0;
		overflow: hidden;
		visibility: hidden;
	}

	&:hover {
		font-weight: bold;
		z-index: 2;
	}
}

.react-tabs__tab--selected {
	font-weight: bold;
	z-index: 2;
	&::after {
		content: "";
		position: absolute;
		height: 6px;
		width: 100%;
		background-color: #f0f0f0;
		bottom: -6px;
		left: 0;

		background-color: #89c732;
		border-top-right-radius: 4px !important;
		border-bottom-right-radius: 4px !important;
		border-top-left-radius: 4px !important;
		border-bottom-left-radius: 4px !important;
	}
}

.react-tabs__tab--disabled {
	color: GrayText;
	cursor: default;
}

.react-tabs__tab:focus {
	box-shadow: 0 0 5px hsl(208, 99%, 50%);
	border-color: hsl(208, 99%, 50%);
	outline: none;
}

.react-tabs__tab:focus:after {
}

.react-tabs__tab-panel {
	display: none;
}

.react-tabs__tab-panel--selected {
	display: block;
}

.backUpDate{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: column;
}
