@import "styles/_variables.scss";

.appWrapper {
	display: flex;
	background: $_bg-content;
	font-size: 16px;

	.appContent {
		box-sizing: border-box;
		height: calc(100vh - #{$_navBar-height});
		padding: 1.5rem 3.25rem;
		background: $_bg-content;
		color: $_textColor-global;
		overflow: auto;
		transition: opacity .3s ease-in-out;

		&.hideOverflow {
			opacity: 0;
			pointer-events: none;
		}
	}
}

.main {
	flex-grow: 1;
	overflow: hidden;

	transition: padding $_seconds-global ease;

	&.collapsed {
		padding-left: 117px;
	}

	&.open {
		padding-left: 280px;
	}
}