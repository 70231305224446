@import "styles/_variables.scss";

.toggleButton {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 5px 5px 0 0;
	border: none;
	vertical-align: middle;
	transition: box-shadow 0.4s ease;
	background-color: transparent;
	color: $_blue-dark;
	width: fit-content;

	.selectMenuIcon {
		display: flex;
		align-items: center;
		font-size: 20px;
	}
	.selectImage {
		border-radius: 50%;
		width: 50px;
		height: 50px;
	}
	.selectName {
		font-weight: 700;
		margin: 0 20px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		vertical-align: middle;
		font-family: "Roboto";
		font-size: 20px;
		font-weight: 400;
		margin: 0;
		padding-right: 5px;
		width: fit-content;
		&:not(.noWidth) {
			min-width: 100px;
		}
	}
}

.statusContainer {
	color: $_green-light;
	padding-left: 5px;
}

.listWrapper {
	list-style: none;
	padding: 0;
	margin: 0;
	min-height: 40px;

	.listItem {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		text-decoration: none;
		cursor: pointer;
		font-size: 17px;
		color: $_textColor-global;
		padding: 8px 12px;
		height: 40px;
		user-select: none;

		b {
			font-weight: 600;
		}
		&:first-child {
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}
		&:last-child {
			border-bottom-left-radius: 10px;
			border-bottom-right-radius: 10px;
		}
		&:hover {
			background-color: #eff5e6;
		}
		&.selected {
			font-weight: bold;
			background-color: #eff5e6;
		}

		.statusContainer {
			color: $_green-light;
			padding-left: 5px;
		}
	}
}

.listContainer {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	display: flex;
	align-items: center;
	display: flex;
	width: 100%;
	justify-content: flex-start;
	color: $_blue-dark;
	width: fit-content;

	&:not(.noWidth) {
		min-width: 100px;
	}
	.toggleButton {
		z-index: 1041;
	}
	.menu {
		z-index: 1042;
		min-width: fit-content;
		&.wideMenu {
			width: 100%;
			min-width: 100px;
		}
	}

	&.readOnly {
		user-select: none;
		pointer-events: none;
		color: gray;
	}

	&.dropDownShown {
		.selectName {
			color: $_textColor-global;
		}
		.selectMenuIcon {
			color: $_textColor-global;
		}
		.selectImage {
			border: 1px solid $_textColor-global;
		}
	}
	.selectContainer {
		display: flex;
		align-items: center;
	}

	.noWidth {
		padding: 0;
		margin: 0;
		width: unset;
		max-width: unset;
		max-width: unset;
	}
}

.menu {
	position: absolute;
	top: 35px;
	left: 1rem;
	max-width: fit-content;
	opacity: 0;
	visibility: hidden;
	border-radius: 10px;
	transform: translateY(-20px);
	transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
	z-index: 1041;
	border: 1px solid #89c732;
	background-color: #fff;
	min-width: 100%;
	margin-bottom: 8px;

	&.active {
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}

	&.showTopArrow::after {
		content: "";
		position: absolute;
		top: -10px;
		left: 50%;
		width: 0px;
		height: 0px;
		border-left: 10px solid transparent;
		border-right: 10px solid transparent;
		border-bottom: 10px solid white;
		z-index: 99999;
		transform: translateX(-50%);
		transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
	}

	&.showShadow {
		box-shadow: 0px 2px 10px #00000027;
		opacity: 1;
		border: none;
	}
	&.menuLeft {
		left: 0;
		right: auto;
	}
	&.menuCenter {
		left: 50%;
		transform: translate(-50%, 0);
	}
	&.menuRight {
		right: 0;
		left: auto;
	}
}

.noOptions {
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 15px;
	font-style: italic;
	height: 40px;
	user-select: none;
	white-space: nowrap;
	padding: 5px 10px;
}
