.backdrop {
    transition: all .3s ease;
    &:not(.hide) {
        position: fixed;
        background-color: #434343;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.2;
        width: 100%;
        height: 100%;
        z-index: 1040;
    }

    &.hide {
        display: none;
    }
}
