@import "styles/_variables.scss";

.container {
  font-family: "Roboto";
  height: 100vh;
  background-color: $_bg-main;
  color: $_blue-dark;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100vw;
  overflow: auto;
  min-height: fit-content;
  min-width: fit-content;

  .left {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    img {
      width: 100%;
      min-width: 500px;
      max-width: 750px;
      padding: 10rem;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem;
  border-radius: 10px;
  margin-left: 0;
  width: 60%;
  max-width: 500px;
  padding-left: 3rem;

  .card {
    background-color: $_bg-form;
    box-shadow: none;
    margin: 0;
    padding: 2rem;
  }

  .cardContentClass {
    > * {
      margin: 2rem;
      margin-top: 0;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;

    * {
      white-space: nowrap;
      padding: 5px;
      margin: 0;
    }

    h4 {
      font-size: 22px;
      font-weight: 600;
    }

    p {
      font-size: 16px;
    }
  }

  .signInButton {
    font-size: 16px;
    margin: 1rem;
    background-color: #89c732;
    border-color: #89c732;
    border-radius: 4px;

    &:hover {
      border-color: $_green-light !important;
      opacity: 0.85;
    }
  }

  .rememberPassword {
    cursor: pointer;
  }

  .loginInput {
    box-shadow: 0 3px 6px #2c28281c;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      padding-left: 1rem;
    }
  }

  .loginMessage {
    padding: 0.5rem 1rem;
    border: 1px solid $_red;
    border-radius: $_global-radius;
    color: $_red;
    margin-bottom: 5px;
    margin-top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: 14px;
    font-family: "Montserrat";
  }

  .loaderWrapper {
    height: 50px;
    width: 123px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
  }
}

.buttonStyle {
  background-color: $_green-light !important;
  color: #fff !important;
  border-color: transparent !important;
}

.signUpLink {
  display: flex;
  font-weight: 700;
  font-size: 15px;
  padding: 15px;

  .link {
    color: $_green-light;
    padding-left: 5px;
    text-decoration: none;
  }
}

.forgotPassword {
  font-size: 15px;
  text-decoration: none;
  color: #261f63;
  cursor: pointer;
  text-align: center;
  margin: auto;
}

.forgotPassword {
  font-size: 15px;
  text-decoration: none;
  color: $_blue-dark;
  cursor: pointer;
}

.forgotPassword:hover {
  color: #afaad8;
}

.inputIcon {
}

@media screen and (max-width: 1010px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    width: 100%;

    .left img {
      padding: 3rem;
      min-width: 50%;
    }

    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }

    form {
      padding: 0;
      margin: 0;
      width: 100%;
    }
  }
}

.eyeSvg {
  color: #2b2467;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  font-size: 18px;
  cursor: pointer;
  position: absolute;
  right: 15px;
  width: 20px;
  height: 20px;
}

.loading {
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: end !important;
  margin: auto !important;
}

.Toastify {
  z-index: 1111111111;
  position: relative;

  .Toastify__toast-container {
    padding-bottom: 40px;
    width: fit-content;
  }

  .Toastify__toast {
    margin-bottom: 10px;
    color: #000000;
    align-items: center;
    border-radius: 25px;
    height: 90px;
    width: 500px;
    padding-left: 120px;
    position: relative;
    font-weight: 500;

    &.Toastify__toast--success {
      background-color: #f4fff4;
      border: solid 1px #3ac32e;

      &::before {
        background-image: url("../../assets/icons/successToastIcon.svg");
      }
    }

    &.Toastify__toast--error {
      background-color: #ffefef;
      border: solid 1px #ff0000;

      &::before {
        background-image: url("../../assets/icons/errorToastIcon.svg");
      }
    }

    &.Toastify__toast--warning {
      background-color: #fff8ef;
      border: solid 1px #ff9300;

      &::before {
        background-image: url("../../assets/icons/warningToastIcon.svg");
      }
    }

    &.Toastify__toast--info {
      background-color: #eaf6ff;
      border: solid 1px #0061a8;

      &::before {
        background-image: url("../../assets/icons/infoToastIcon.svg");
      }
    }

    &::before {
      content: "";
      width: 60px;
      height: 60px;
      position: absolute;
      left: 30px;
      background-size: 100%;
    }
  }

  .Toastify__close-button {
    background: none;
    margin: auto 5px;
    width: 40px;
    height: 40px;
    border-radius: 50%;

    &:hover {
      background-color: #ffffff;
    }

    svg {
      fill: black;
      width: 20px;
      height: 20px;
    }
  }
}

.fakeCaretContainer {
  position: absolute;
  pointer-events: none;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: caret-blink 1.2s ease-out infinite;
}

.fakeCaret {
  width: 1px;
  height: 2rem;
  background-color: white;
}

@keyframes caret-blink {
  0%,
  70%,
  100% {
    opacity: 1;
  }
  20%,
  50% {
    opacity: 0;
  }
}

.modalTitle {
  font-size: 25px;
  color: $_green-light;
}

.slotContainer {
  position: relative;
  width: 2.5rem; /* Equivalent to w-10 in Tailwind */
  height: 3.5rem; /* Equivalent to h-14 in Tailwind */
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  border-color: #261f63;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-right: 1px solid;
  border-left: 1px solid;
}

.outlineNone {
  outline: 0;
  outline-color: var(
    --accent-foreground-20
  ); /* Custom property for accent outline color */
}
.active {
  outline: 4px solid var(--accent-foreground); /* Custom property for active outline color */
}

.fakeDashContainer {
  display: flex;
  width: 2.5rem; /* Equivalent to w-10 in Tailwind */
  justify-content: center;
  align-items: center;
}

.fakeDash {
  width: 0.75rem; /* Equivalent to w-3 in Tailwind */
  height: 0.25rem; /* Equivalent to h-1 in Tailwind */
  border-radius: 9999px; /* Equivalent to rounded-full in Tailwind */
  background-color: #89c732; /* Custom property for border color */
}
