.calendarContainer{
    margin-top: 23px;
    position: relative;
    height: calc(100vh - 69px - 191px );

    .showAllOrdersButton{
        position: absolute;
        left: 300px;
        top: 50px;
        font-size: 18px;
        background-color: var(--dark-blue);
        padding: 5px 15px;
        border-radius: 5px;
        cursor: pointer;
        color: white;
        p{
            margin: 0;
        }
    }
}