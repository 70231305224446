@import "styles/_variables.scss";

.allTemplatesBox{
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 50px;
}

.appContent{
  display: flex;
  flex-direction: column;
  .header{
    display: flex;
    justify-content: space-between;
    width: 100%;
    span{
      padding-right: 60px;
    }
  }
}

.plusIcon{
  width: 24px;
  height: 24px;
}

.addTemplateBox{
  display: flex;
  align-items: center;
  padding:20px 60px;
  margin-right: 20px;
  height: 54px;
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 2px 8px 16px #18327314;
  cursor: pointer;

  .addTemplateText{
    font-size: 20px;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 0px !important;
    font-face-name:"Roboto";
  }
}

.deleteTemplateBox{
  display: flex;
  align-items: center;
  padding:20px ;
  height: 54px;
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 2px 8px 16px #18327314;
  cursor: pointer;

  .deleteTemplateText{
    font-size: 20px;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 0px !important;
    font-face-name:"Roboto";
  }
}

.actionButtons{
  display: flex;
}

.modalContent{
  min-width: 500px;
}

.addVacationModal{
  padding-bottom: 30px;
  span{
    font-size: 22px;
    color:$_green-light;
  }
}

.formTemplateBox{
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
  padding: 0 30px;
}

.templateName{
  display: flex;
  align-items: center;
  min-width: 300px;
  padding:20px 20px;
  margin-right: 60px;
  height: 54px;
  border-radius: 7px;
  background-color: #fff;
  box-shadow: 2px 8px 16px #18327314;
  cursor: pointer;

  .uploadIcon{
    transform: rotate(90deg);
    margin-right: 10px;
  }
  .textUpload{
    color: $_blue-dark;
    font-size: 20px;
    font-weight: 500;
  }
}

.rowInput {
  margin: 0 ;
  &:read-only,
  input:read-only {
    border-bottom: 1px solid transparent;
    cursor: auto;
  }

  input::placeholder{
    font-weight: 500;
    font-size: 20px;
    color:$_blue-dark;
  }
}

.button{
  border: none;
}
.attachmentRow {
  align-items: flex-end;
}
.attachmentRowInput {
  display: none;
}
.attachmentRowButton {
  padding: 7px 10px;
  min-height: fit-content;
  max-height: fit-content;
  margin: 0;
  background-color: white;
  color: $_blue-dark;
  width: 100%;
  font-size: 16px;

  &.contractFilePicked {
    font-style: italic;
    max-width: 100%;
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
    width: 195px;
    display: block;
    min-width: 100%;
  }
}

.attachmentsName{
  font-style: initial;
}

.loader{
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  height: 100%;
}

@media screen and (max-width: 1024px) {
  .header{
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    span{
      padding-right: 60px;
    }
  }

  .actionButtons{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
}

.imageDocuments{
  display: flex;
  flex-direction: column;
  width: 400px;
  margin: auto;
		div {
			padding: 2rem 1rem;
		}
		small {
			color: #261F63;
			font-size: 18px;
      text-align: center;
      font-weight: 500;
		}
		img {
			width: 180px;
      margin: auto;
		}
}

.noDocumentWrapper{
margin: 10% 0 0 40%;

 
}

.noDocumentHeader{
  text-align: center;

}
.noDocumentText{
  text-align: center;
  color:$_green-light;
  cursor: pointer;
  font-weight: 500;
}