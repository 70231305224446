.card {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 3px 6px #2c28281c;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    margin: 1.5rem 3rem;
    .title {
        font-weight: bold;
        display: flex;
        padding-left: 10px;
        padding-right: 10px;
        font-size: 21px;
    }
    .content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly;
        flex: 1;
    }
}
