@import "styles/_variables.scss";

.template {
	display: flex;
	margin-bottom: 31px;
	min-width: 33%;

	&:last-child {
		margin-right: 0;
	}

	.templateBox {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		min-width: 300px;
		box-shadow: 2px 8px 16px #18327314;
		background-color: #ffffff;
		border-radius: 7px;
		margin-right: 60px;
		cursor: pointer;
		padding: 7px 20px;

		.folderIcon {
			width: 30px;
			height: 28px;
			margin-top: 5px;
		}

		.templateTitle {
			color: $_blue-dark;
			font-family: "Roboto";
			font-weight: 700;
			margin-left: 10px;
			font-size: 20px;
			color: #261f63;
		}
	}
}

.firstBox {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 7px 20px 7px 0px;
	vertical-align: center;
}

@media screen and (max-width: 1580px) {
  .template {
		.templateBox {
			margin-right: 30px;
		}
	}

}

@media screen and (max-width: 1170px) {
	.template {
		min-width: 99%;
	}
  .template {
		.templateBox {
			margin-right: 20px;
		}
	}
}