@import "styles/_variables.scss";

.pillsContainer {
	width: 100%;
	font-size: 20px;
}
.tabList {
	display: flex;
	align-items: center;
	width: 100%;
	padding-left: 0;
	padding-top: 5px;
	padding-bottom: 1.5rem;
	margin: 0;
}

.tabListItem {
	position: relative;
	display: inline-block;
	margin-bottom: 2px;
	padding: 4px 0;
	width: 15rem;
	min-width: fit-content;
	text-align: center;
	cursor: pointer;
	white-space: nowrap;
	user-select: none;

	transition: font-weight .025s ease;
	> div {
		&::before {
			display: block;
			content: attr(title);
			font-weight: bold;
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}
	}

	&.active
	,
	&:hover 
	{
		font-weight: bold;
	}
	&::after {
		content: "";
		position: absolute;
		height: 6px;
		width: 100%;
		background-color: #f0f0f0;
		bottom: -6px;
		left: 0;
	}
	&:first-child::after {
		border-top-left-radius: 4px;
		border-bottom-left-radius: 4px;
	}
	&:nth-last-of-type(2)::after {
		border-top-right-radius: 4px;
		border-bottom-right-radius: 4px;
	}
	&.active::after
	{
		background-color: $_green-light;
		border-top-right-radius: 4px !important;
		border-bottom-right-radius: 4px !important;
		border-top-left-radius: 4px !important;
		border-bottom-left-radius: 4px !important;
	}
}

.tabListActive {
	background-color: white;
	border: solid #ccc;
	border-width: 1px 1px 0 1px;
}

li.seachItem {
	flex: 1;
	display: flex;
	justify-content: flex-end;
	padding-left: 2rem;
}

.paginateWrapper {
	height: calc(100vh - 362px);
	overflow: auto;
}

.tabContent {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: center;
}
