@import "styles/_variables.scss";

.shortcut {
    text-decoration: none;
    color: inherit;
    flex: 0 0 25%;
    display: flex;
    justify-content: center;
    margin: 15px 10px;

    .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100px;
        svg {
            color: $_green-light;
            width: 45px;
            height: 45px;
            
        }
        .title {
            padding-top: 5px;
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            text-decoration: none;
        }
    }
}