@import "styles/_variables.scss";

.modalWrapper {
	position: fixed;
	display: block;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 auto;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 1099;
	overflow-x: hidden;
	background-color: #43434333;
	pointer-events: none;
	opacity: 0;
	transition: opacity 250ms 100ms ease-in-out;
}

.visible {
	pointer-events: auto;
	opacity: 1;
}

.modal {
	position: relative;
	display: block;
	width: 200px;
	min-height: fit-content;
	min-width: 400px;

	margin: 0 auto;
	margin-top: 20px;
	margin-bottom: 20px;
	border-radius: 20px;
	padding-bottom: 20px;
	background-color: #fafafa;
	align-self: center;
	box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
	opacity: 0;
	transform: scale(0.6);
	transform: scale(0);
}

.visible .modal {
	opacity: 1;
	transform: scale(1);
}

.modalWrapper {
	.closeButton {
		color: #261f63;

		border-radius: 50%;
		border: 2px solid #261f63;
		background-color: #fff;
		width: 30px;
		height: 30px;
		font-size: 20px;
		font-family: "Montserrat";
		cursor: pointer;
		line-height: 1rem;
		margin: 0;
		padding: 1px;
		min-width: 31px;
		min-height: 31px;
		max-width: 31px;
		max-height: 31px;

		position: absolute;
		right: -15px;
		top: -15px;
		z-index: 1046;
		svg {
			width: 31px;
			height: 31px;
		}
		&:hover {
			opacity: 1;
		}
	}

	.modal {
		padding: 35px;
		display: flex;
		flex-direction: column;
		min-width: fit-content;
	}
	.submitBtn {
		min-width: 155px;
	}

	.header {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		padding: 5px;
		padding-top: 0;
		font-size: 18px;

		.title {
			color: $_green-light;
		}
	}
	.content {
		overflow-y: auto;
		flex: 1;
		padding: 5px;
	}

	.footer {
		display: flex;
		justify-content: flex-end;
		.btn {
			font-size: 16px;
			margin-bottom: 0;
			margin-right: 0;
			margin-top: 5px;
		}
	}
	.loaderWrapper {
		min-width: 155px;
		height: 51px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 5px;
		.loader {
			height: 30px;
			width: 30px;
		}
	}
}
