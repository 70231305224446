@import "styles/_variables.scss";

.globalSearchContent {
  position: absolute;
  top: calc(#{$_navBar-height} + 1px);
  left: 0;
  min-height: 100px;
  width: 100%;
  background-color: white;
  color: $_textColor-global;
  z-index: 1041;
  padding: 30px;
  border-radius: 0 0 15px 15px;
}
