@import "styles/_variables.scss";

.appContent {
  padding: 0 !important;
}

.employeesContainer {
  padding: 0 1rem;
  position: relative;
  height: fit-content;
  min-width: fit-content;
  width: 100%;
  height: 100%;
  padding: 1.5rem 3rem;
  transition: opacity 0.1s ease;
  .headerButton {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    min-width: 140px;

    font-weight: 500;
    &:first-child svg {
      stroke: $_green-light;
    }

    &.employeesModalButton svg {
      fill: $_green-light;
    }

    &:last-child {
      margin-right: 0;
    }
    svg {
      width: 20px;
      height: 20px;
    }
    .buttonTitle {
      font-weight: 500;
      font-size: 18px;
    }
  }
  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 8px;
  }
}

.tableContainer {
  flex-direction: column;
  padding-top: 1rem;

  .loaderWrapper {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tabContentClass {
    height: calc(100vh - 373px);
    min-height: calc(100vh - 373px);
  }
}

.navTabsContainer {
  .navtabsPill {
    position: relative;
    font-size: 20px;
    display: inline-block;
    margin-bottom: 1px;
    padding: 2px 4rem;
    cursor: pointer;

    &.active {
      text-shadow: 1px 0 0 currentColor;
    }
    &::after {
      content: "";
      position: absolute;
      height: 5px;
      width: 100%;
      background-color: #f0f0f0;
      bottom: -6px;
      left: 0;
    }
    &:first-child::after {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &:last-child::after {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    &.active::after {
      background-color: $_green-light;

      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .active {
      background-color: white;
      border: solid #ccc;
      border-width: 1px 1px 0 1px;
    }
  }
}
.nameInput {
  text-transform: capitalize !important;
}

.attachmentModal {
  color: $_blue-dark;
  .title {
    color: $_green-light;
    font-weight: 500;
    display: flex;
    justify-content: flex-start;
    font-size: 18px;
    padding-bottom: 1rem;
  }
  .title {
    text-transform: capitalize !important;
  }

  .row {
    border: 1px solid rgba(211, 211, 211, 0.589);
    padding: 4px;
    margin-bottom: 5px;
    display: flex;
    border-radius: 6px;
  }

  .content {
    margin-bottom: 1.5rem;
    padding-right: 0px;
  }

  .button {
    font-size: 13px;
    padding: 5px 15px;
    min-height: unset;
    font-weight: normal;
    border-radius: 8px;
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .nameWrapper {
    flex: 1;
    display: flex;
    align-items: center;
  }
  .name {
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-decoration: none;
    width: fit-content;
    text-align: left;

    max-width: 100%;
    min-width: 600px;

    a {
      color: $_blue-dark;
      overflow: hidden;
      text-overflow: ellipsis;
      width: fit-content;
      display: block;
      height: fit-content;
      white-space: nowrap;
      &:hover {
        opacity: 0.75;
        text-decoration: underline;
      }
    }
  }

  .attachmentsHeader {
    font-size: 24px;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    small {
      font-size: 14px;
    }
  }
}
