@import "styles/_colors.scss";

$_bg-main: #fafafa;
$_bg-form: #f0f0f3;
$_bg-content: #fafafa;
$_bg-header: $_blue-dark;
$_bg-sideBar: #fff;
$_seconds-global: 0.4s;
$_navBar-height: 87.3px;
$_sideBar-width: 300px;
$sidebar-width-collapsed: 117px;
$_textColor-global: $_blue-dark;
$_global-radius: 4px;
$_radius-round: 50%;

$_content-padding: 2rem 2.5rem;
