@import "styles/_variables.scss";

.menu {
  position: absolute;
  top: 100px;
  right: 5px;
  left: 0;
  max-width: fit-content;
  opacity: 0;
  visibility: hidden;
  border-radius: 8px;
  background-color: $_bg-content;
  transform: translateY(-20px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s ease;
  z-index: 1041;

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  .listWrapper {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .listItem {
    display: flex;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
    font-size: 14px;
    color: $_textColor-global;
    padding: 15px 60px;

    .statusContainer {
      color: $_green-light;
      padding-left: 5px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.034);
    }
    &.selected {
      font-weight: bold;
      background-color: rgba(0, 0, 0, 0.034);
    }
  }
}

.dropDownShown {
  background-color: #fff;
  border-left: 1px solid $_blue-dark;

  .userName {
    color: $_textColor-global;
  }
  .userMenuIcon {
    color: $_textColor-global;
  }
  .userImage {
    border: 1px solid $_textColor-global;
  }
}

.toggleButton {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease;
  margin-left: auto;
  background-color: transparent;
  color: white;
  width: 100%;

  .userMenuIcon {
    display: flex;
    align-items: center;
    font-size: 20px;
  }
  .userImage {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
  .userName {
    font-weight: 700;
    vertical-align: middle;
    font-size: 14px;
    margin: 0 20px;
    min-width: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.menu {
  position: absolute;
  top: 100px;
  right: 5px;
  left: 0;
  max-width: fit-content;
  opacity: 0;
  visibility: hidden;
  border-radius: 8px;
  background-color: $_bg-content;
  transform: translateY(-20px);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  z-index: 1041;
  &::after {
    content: "";
    position: absolute;
    top: -10px;
    left: 30px;
    width: 0px;
    height: 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    z-index: 99999;
  }

  &.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
}

.statusContainer {
  color: $_green-light;
  padding-left: 5px;
}

.listWrapper {
  list-style: none;
  padding: 0;
  margin: 0;

  .listItem {
    display: flex;
    justify-content: center;
    text-decoration: none;
    cursor: pointer;
    font-size: 16.5px;
    color: $_textColor-global;
    padding: 15px 60px !important;
    b {
      font-weight: 600;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #89c73233;
    }
    &:first-child {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
    &:last-child {
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    &:hover {
      background-color: #eff5e6;
    }
    &.selected {
      font-weight: bold;
      background-color: #eff5e6;
    }
  }
}

.listContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 15px;
  min-width: 300px;
  z-index: 1041;
  background-color: $_bg-header;
  border-left: 1px solid #fff;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 1043;

  &.dropDownShown {
    background-color: #fff;
    border-left: 1px solid $_blue-dark;

    .userName {
      color: $_textColor-global;
    }
    .userMenuIcon {
      color: $_textColor-global;
    }
    .userImage {
      border: 1px solid $_textColor-global;
    }
  }
  .userContainer {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .toggleButton {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border: none;
    vertical-align: middle;
    transition: box-shadow 0.4s ease;
    margin-left: auto;
    background-color: transparent;
    color: white;
    width: 100%;

    .userMenuIcon {
      display: flex;
      align-items: center;
      font-size: 20px;
    }
    .userImage {
      border-radius: 50%;
      width: 50px;
      height: 50px;
    }
    .userName {
      vertical-align: middle;
      margin: 0 20px;
      min-width: 100px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      font-family: "Roboto";
      font-size: 16px;
      font-weight: 400;
    }
  }
}
