@import "styles/_variables.scss";

.confirmModal {
	padding: 0 !important;
	color: $_blue-dark;
	.content {
		min-width: 350px;
	}
	.title {
		font-size: 22px;
		font-weight: 500;
	}

	.subTitle {
		padding: 1rem 0 2rem;
		font-size: 15px;
	}
}

.confirmFooter {
	button {
		margin-left: 1.5rem;
		padding: 5px 15px;
		min-height: 45px;
	}

	button:first-child {
		margin-left: 0;
	}
}

.confirmModalMainStyle {
	width: 500px;
	padding: 2rem !important;
    padding-top: 1.5rem !important;
}

.confirmModalBackdrop {
	z-index: 1100 !important;
}