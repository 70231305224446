@import "styles/_variables.scss";

.appContent {
	padding: 0 !important;
}

.dashboard {
	display: flex;
	flex-wrap: wrap;
	min-width: fit-content;
	padding: 1.5rem 3.25rem;

	.shortcutsCard {
		flex: 1;
		&:first-of-type {
			margin: 1.5rem;
			margin-left: 3rem;
		}
	}
	.shortCard {
		flex: 1;
		&:first-child {
			margin-bottom: 1rem;
		}
		&:nth-of-type(2) {
			margin-top: 24px;
		}
		margin-left: 1rem;
	}
	.rightCards {
		display: flex;
		flex-direction: column;
		flex: 1;
	}

	.lineIconCustom {
		svg {
			border-radius: 50%;
			fill: white;
			width: 50px;
			height: 50px;
			padding: 5px;
		}
		margin: 0;
	}

	.greenBg {
		:not(svg) {
			background-color: $_green-light;
		}
		svg {
			fill: $_green-light;
		}
	}
	.purpleBg {
		:not(svg) {
			background-color: $_purple;
		}
		svg {
			fill: $_purple;
		}
	}

	.svgType * {
		border-radius: 50%;
	}

	.svgIcon,
	.svgType svg {
		width: 60px;
		height: 60px;
	}
	.widget {
		.widgetContent {
			width: 100%;
			padding: 5px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			font-weight: 400;
		}
		.top {
			display: flex;
		}
	}

	.iconUser {
		position: relative;
		.iconPlus {
			position: absolute;
			bottom: -5px;
			left: -15px;
			width: 35px;
			height: 35px;
		}
	}
	.numberCard {
		flex: 1;
		padding-top: 5px;
	}

	.percentageNumber {
		font-size: 14px;
		padding-right: 20px;
		color: $_green-light;
	}
}

.bottomCardsContainer {
	display: flex;
	flex: 1 1 100%;
	justify-content: space-between;

	.bottomCard {
		display: flex;
		flex: 1 1 20%;
		width: 100%;
		min-width: -moz-fit-content;
		min-width: fit-content;
		padding: 1rem 1.5rem;
		margin: 0;
		min-width: 230px;
		position: relative;

		.cardTitle {
			flex: 1 1 80%;
			max-width: 80%;
			padding: 0;
			padding-right: 5px;
			flex: unset;
			font-size: 18px;
		}
		position: relative;
		.bottomCardContent {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			&:not(:last-child) {
				padding-top: 15px;
			}
		}
		.holidaysCardContent {
			min-height: calc(318px + 40px);
			max-height: calc(318px + 40px);
			display: flex;
			flex-direction: column;
			flex-wrap: nowrap;
			.columnWrapper {
				max-height: 100%;
				&.unsetHeight {
					min-height: unset;
				}
			}
		}
		.numberCardTitle {
			font-size: 15px;
			font-weight: 500;
		}
		.numberCardNumber {
			color: gray;
			font-size: 13px;
			padding-top: 4px;
			white-space: nowrap;
		}
	}
	.threeDotsButton {
		z-index: 1042;
		position: absolute;
		top: 25px;
		right: 5%;
		min-height: unset;
	}
	.moreContent {
		font-size: 13px;
		width: fit-content;
		position: absolute;
		top: 10px;
		right: 14px;
		display: flex;
		align-items: center;
		cursor: pointer;
		user-select: none;
	}
	.columnWrapper {
		display: flex;
		flex-direction: column;
		min-height: 318px;
		max-height: 318px;
		overflow: auto;
	}

	.userDataContainer {
		display: flex;
		align-items: center;
		border-bottom: 1px solid lightgray;
		& > * {
			margin-right: 10px;
		}

		.numberCard {
			padding: 1.5rem 0;
		}
	}
	.cardImagesContainer {
		display: flex;
		justify-content: flex-end;

		img {
			width: 20px;
			height: 20px;
			border-radius: 50%;
		}
	}

	.todayDate {
		font-size: 13px;
		padding: 5px 10px 5px 0;
		color: $_blue-dark;
		opacity: 0.5;
	}

	.birthdaysCount {
		padding: 5px 10px 5px 0;
		font-size: 28px;
		font-weight: 400;
		color: $_blue-dark;
		opacity: 0.5;
	}

	.cardVacations {
		.cardTitle {
			flex: unset;
		}
		.cardVacationsImage {
			img {
				width: 50px;
				height: 50px;
				padding: 5px;
				border-radius: 50%;
			}
		}
		.cardVacationsTitle {
			font-size: 13px;
			color: gray;
			white-space: nowrap;
		}

		.bottomCardContent {
			justify-content: flex-start;
		}
	}

	.expiredContract {
		.contractsNumberCard {
			margin: 1.5rem 0;
			padding: 10px 10px 10px 15px;
			border-left: 3px solid;
		}

		&:nth-of-type(even) {
			.contractsNumberCard {
				border-color: $_red;
			}
		}
		&:nth-of-type(odd) {
			.contractsNumberCard {
				border-color: $_green-light;
			}
		}
	}

	.bottomContainer {
		display: flex;
		padding: 1.5rem 3rem;
		flex: 1;
		&:first-child {
			padding-right: 2rem;
		}
		&:last-child {
			padding-left: 2rem;
		}
		&:nth-child(2) {
			padding-left: 2rem;
			padding-right: 2rem;
		}
		&:first-child .bottomCardContent {
			padding-top: 1.5rem;
		}
	}
	.loaderWrapper {
		flex: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}

	.nobookingsContent {
		text-align: center;
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		font-size: 20px;
		white-space: nowrap;

		div {
			padding: 2rem 2rem;
			padding-top: 22px;
		}
		small {
			color: $_green-light;
			font-size: 18px;
		}
		img {
			width: 180px;
		}
	}

	.noContractsContent {
		padding-top: 11px;
		padding: 0 1rem;
		img {
			padding: 2rem 1rem;
			width: 180px;
		}
	}

	.noBirthdaysContent,
	.noContractsContent {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		text-align: center;
	
		font-size: 20px;
		height: 100%;
		min-width: 273px;
		div {
			padding: 1rem;
			padding-top: 11px;
		}
		img {
			width: 180px;
		}
	}
}

.externalLink {
	text-decoration: none;
	color: $_blue-dark;
}
