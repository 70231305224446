@import "styles/_variables.scss";

.button_ {
	min-height: 50px;
	min-width: 64px;
	padding: 15px 35px;
	margin: 8px;
	border: 1px solid $_blue-dark;
	border-radius: 10px;
	color: #1976d2;
	background-color: $_blue-dark;
	transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, filter 250ms ease, opacity 250ms ease;

	box-sizing: border-box;
	font-size: 0.875rem;
	font-family: "Roboto", sans-serif;
	font-weight: 500;
	letter-spacing: 0.02857em;
	white-space: nowrap;
	cursor: pointer;
	text-decoration: none;
	outline: none;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: fit-content;
	user-select: none;
	transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
	&.noHeight {
		min-height: unset;
	}

	&:hover {
		background-color: #47417a;
		border-color: #47417a !important;
	}

	&.shell {
		background-color: white;
		color: $_blue-dark;
		border-width: 1px;
		&:hover {
			background-color: #f3f9eb !important;
		}
	}

	&.shadow {
		border: none;
		color: #261f63;
		background-color: #fff;
		box-shadow: 0 3px 6px #2c28281c;
		font-size: 18px;
		font-weight: 500;

		&:hover {
			background-color: #f3f9eb !important;
		}
	}

	&.light {
		color: $_blue-dark !important;
		background-color: transparent !important;
		border: 1px solid transparent !important;
		box-shadow: none !important;
		padding-right: 5px;
		padding-left: 5px;
		margin-right: 1rem;
		filter: none !important;
		&:hover {
			filter: none !important;
			&:not(.noHover) {
				background-color: #89c7321a !important;
			}
		}
		&:first-of-type {
		}
	}

	&.fixed {
		width: 163px;
		min-width: 163px;
		max-width: 163px;
		height: 50px;
		min-height: 50px;
		max-height: 50px;
	}

	.loading {
		height: 20px;
		width: 20px;
	}

	&.noPadding {
		padding: 0;
	}
	&.noMargin {
		margin: 0 !important;
	}
	&[disabled] {
		cursor: none;
		opacity: 0.5;
		pointer-events: none;
	}
}
