@import "styles/_variables.scss";

.pt2 {
  padding-top: 2rem;
}


.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 1.5rem;
  padding-right: calc(1rem + 30px);

  .button {
    min-width: 150px;
    max-height: 50px;
    font-weight: bold;
    margin-left: 1rem;
    font-size: 16px;

    &:not(.shellButton) {
      margin-right: 0;
    }
  }


  .close {
    background-color: transparent;
    color: black;
  }

  .submitLoader {
    height: 35px;
    width: 35px;
  }
}

.headerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  width: 30px;

  font-weight: 500;
  &:first-child svg {
    stroke: $_green-light;
  }

  &.employeesModalButton svg {
    fill: $_green-light;
  }

  &:last-child {
    margin-right: 0;
  }
  svg {
    width: 20px;
    height: 20px;
  }
  .buttonTitle {
    font-weight: 500;
    font-size: 18px;
  }
}
.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
}

.header {
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.deleteCompanyMessage {
	font-size: 20px;
	font-family: "Roboto";
	font-weight: 500;
	color: #261f63;
	margin-bottom: 20px;
}

.buttonsContainer {
  padding: 0;
  padding-bottom: 2rem;
  display: flex;
  justify-content: flex-end;

  .icon {
    padding-right: 1.5rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.8;
    }

    svg {
      width: 25px;
      height: 25px;
    }
  }

  .trash {
    padding-right: 0;

    svg {
      stroke: $_red;
      stroke-width: 1.5px;
    }
  }
}

.footer {
  display: flex;
  justify-content: flex-end;
  padding-top: 1.5rem;
  padding-right: calc(1rem + 30px);

  .button {
    min-width: 150px;
    max-height: 50px;
    font-weight: bold;
    margin-left: 1rem;
    font-size: 16px;

    &:not(.shellButton) {
      margin-right: 0;
    }
  }

  .submitLoader {
    height: 35px;
    width: 35px;
  }
}

.body {
  display: flex;

  .subWrapper {
    padding: 1.5rem 3rem;
    box-shadow: inset 4px 4px 20px #ffffff80, 30px 30px 80px #3754aa1a;
    background-color: #fff;
    border-radius: 20px;
    margin-right: 1rem;
  }
}

.closeModal {
  color: $_blue-dark;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #261f63;
  min-width: 31px;
  min-height: 31px;
  max-width: 31px;
  max-height: 31px;
  font-size: 20px;
  font-family: "Montserrat";
  background: transparent;
  cursor: pointer;
  line-height: 1rem;
  margin: 0;
  padding: 0;

  div {
    font-weight: 300;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  svg {
    width: 25px;
    height: 25px;
  }
}

.tabPills {
  font-size: 20px;
  color: $_blue-dark;
  min-width: fit-content;
  //width: 50vw;

  ul li {
    padding: 4px 2rem;
    width: unset;
    min-width: unset;

    &:not(.active):nth-of-type(3)::after {
      border-radius: unset;
    }

    &:last-of-type::after {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.modalPage {
  width: 100%;
  display: flex;
  flex-direction: column;

  .row {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    border-radius: $_radius-round;
    height: 100px;
    width: 100px;
    min-width: 100px;
    min-height: 100px;
    position: relative;
    overflow: hidden;

    &.unset {
      border: 2px solid $_green-light;

      svg {
        opacity: 1;
      }
    }

    &.cursorPointer {
      cursor: pointer;
    }

    svg {
      width: 90%;
      height: 80%;

      * {
        stroke-width: 10px;
      }

      transform: scaleX(-1);
      opacity: 0.4;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: $_radius-round;
    }
  }

  .title {
    margin-left: 2rem;
    font-size: 28px;
    font-weight: bold;
    border-bottom: 1px solid transparent;
  }

  .row {
    &>div:first-child {
      white-space: nowrap;
    }

    &>div:last-child {
      margin: 0;
    }

    display: flex;
    align-items: center;
  }

  .mainRow {
    padding-bottom: 0;
    justify-content: center;
    align-items: center;

    .nameInput {
      margin: 0;
      min-width: 200px;
    }

    .nameInput input {
      width: 25%;
    }
  }

  .contentRow {
    padding: 10px calc(2rem + 200px) 10px calc(2rem + 100px);
    font-size: 20px;

    &:nth-of-type(2) {
      padding-top: 0;
    }

    .rowTitle {
      flex: 1;
      text-align: left;
      color: $_green-light;
      font-size: 20px;
      font-weight: 500;
    }

    .rowInput {
      flex: 1;
      font-weight: 400;

      input {
        font-size: 20px;
      }
    }

    .rowTitle1 {
      text-align: left;
      color: $_green-light;
      font-size: 20px;
      font-weight: 500;
    }
  }

  .rowInput {
    input {
      border-bottom: 1px solid #261f6333;
      padding: 3px 0;
      flex: 1;
    }
  }

  .rowInput {

    &:read-only,
    input:read-only {
      border-bottom: 1px solid transparent;
      cursor: auto;

      &::placeholder {
        color: transparent;
      }
    }

    input:disabled {
      color: transparent;
    }
  }
}

.salaryInfoContent,
.employeeInfoContent {
  .contentRow {
    padding: 0;
    padding-top: 3rem !important;
  }

  .colHalf {
    flex: 1 1 50%;

    &:first-child {
      padding-right: 2rem;
    }

    &:last-child {
      padding-left: 2rem;
    }

    .row {
      padding: 10px 0;
      display: flex;
    }

    .rowTitle {
      white-space: normal !important;
      padding-right: 12px;
      min-width: 243px;
      white-space: nowrap;
    }

    .rowInput {
      text-align: left;
      padding-left: 12px;
      flex: 1 1 50%;
    }

    .rowInput2 {
      text-align: left;
      padding-left: 12px;
      flex: 1 1 50%;
      margin-right: 121px !important;
    }

    .rowInput2 {
      text-align: left;
      font-weight: 500;
      flex: 1 1 50%;
      padding-left: 53%;
      margin-bottom: 4px;
    }

    .rowInput3 {
      text-align: left;
      font-weight: 500;
      flex: 1 1 50%;
      padding-left: 53%;
      margin-bottom: 4px;
      margin-top: -22px;
    }
  }

  .contentRow {
    display: flex;
    align-items: initial;
  }
}

.shellButton {
  background-color: white;
  color: $_blue-dark;
  border-width: 2px;

  &:hover {
    border-color: #261f63a6;
  }
}

.modalRoot {
  z-index: 1099 !important;
  color: $_blue-dark;

  .responsiveModal {
    border-radius: 20px;
    padding-left: calc(2rem + 30px);
    max-width: fit-content !important;
    margin: 0;
    background-color: #f7f7f7;
  }

  .modalContainer {
    overflow-x: auto;
    padding: 2rem;
    padding-top: 8%;
  }
}

.customSelect {
  svg {
    height: 25px;
    width: 25px;
  }

  .inputClassName,
  input {
    max-width: 0 !important;
    max-height: 0;
    padding: 0;
    border: 0;
  }
}

.tableRow {
  padding-top: 20px;
  padding-bottom: 10px;
}

.additionDeductionTable {
  width: 100%;
  border: 1px solid #89c732;
  border-spacing: 0;
  border-radius: 20px;
  background: #1f196005;
  font-size: 19px;

  thead {
    th {
      &:not(:last-child) {
        border-right: 1px solid lightgray;
      }

      border-bottom: 1px solid lightgray;
      min-width: 100px;

      .valueTh {
        border-bottom: 1px solid lightgray;
      }

      div>div:not(.valueTh) {
        padding: 5px 0;
      }
    }

    tr:first-child {
      th {
        font-weight: 500;
      }
    }

    tr:nth-child(2) {
      th {
        font-weight: 400;
      }
    }
  }

  tr {
    td {
      &:not(:last-child) {
        border-right: 1px solid lightgray;
      }
    }

    &:not(:last-child) {
      td {
        border-bottom: 1px solid lightgray;

        &:not(:last-child) {
          border-right: 1px solid lightgray;
        }
      }
    }
  }

  td {
    text-align: left;
    padding: 10px 15px;
    height: 48px;
  }

  th {
    padding: 12px 15px;
  }

  .tableInput {
    margin: 0;
    max-width: fit-content;
    background-color: inherit;

    input {
      width: 100%;
      min-width: fit-content;
      font-size: 19px;
      padding-bottom: 0;
    }

    &.euroSignInput::before {
      display: flex;
      align-items: center;
    }

    &.percentageSignInput::before {
      display: flex;
      align-items: center;
    }
  }

  .salaryTableValue {
    max-width: 100px;

    .rowInput::before {
      height: 100%;
      display: flex;
      align-items: center;
      padding-left: 5px;
    }
  }
}

.attachmentRow {
  align-items: flex-end;
}

.attachmentRowInput {
  display: none;
}

.attachmentRowButton {
  padding: 7px 10px;
  min-height: fit-content;
  max-height: fit-content;
  margin: 0;
  background-color: white;
  color: $_blue-dark;
  width: 100%;
  font-size: 16px;

  &.contractFilePicked {
    font-style: italic;
    max-width: 100%;
    overflow: hidden;
    font-size: 14px;
    text-overflow: ellipsis;
    width: 195px;
    display: block;
    min-width: 100%;
  }
}

.uploadImageContainer {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  div {
    overflow: hidden;
    font-size: 14px;
    padding: 2px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .plusIcon {
    border: 1px solid $_blue-dark;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    position: absolute;
    bottom: 15px;
    right: 14px;
  }
}

.percentageSignInput {
  position: relative;

  &::before {
    content: "\0025";
    bottom: 12px;
    right: 0px;
    top: 0px;
    position: absolute;
    z-index: 0;
    opacity: 0.8;
    height: 100%;
  }

  input {
    padding-right: 15px !important;
  }
}

.euroSignInput {
  position: relative;

  &::before {
    content: "\20AC";
    bottom: 12px;
    right: 0px;
    top: 0px;
    position: absolute;
    z-index: 0;
    opacity: 0.8;
    height: 100%;
  }

  input {
    padding-right: 15px !important;
  }
}

.pointerEventsNone {
  pointer-events: none;
}

[contenteditable] {
  cursor: text !important;
}

.nameRow {
  .contentEditableInput[placeholder]:empty:before {
    content: attr(placeholder);
    position: absolute;
    background-color: transparent;
    max-width: 100%;
    opacity: 0.8;
  }

  min-height: 100px;

  .contentEditableInput {
    cursor: auto;
    height: 35px;

    &.firstName {
      width: 68px;
    }

    &.lastName {
      width: 105px;
    }

    max-width: 20%;
    text-align: left;
    min-width: fit-content;

    &:first-child {
      padding-right: 1rem;
    }

    &:focus {
      outline: none;
    }
  }
}

.selectOptionLabel {
  display: flex;
  color: red;
  background-color: blue;

  &.selected {
    background-color: #eff5e6;
  }
}

.familyMemberRowContent {
  border: 1px solid lightgray;
  border-radius: 10px;
  font-size: 17px;
  width: 50%;
  max-width: 50%;
  min-width: fit-content;

  &.row {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &.row:empty {
    padding: 0;
    border: 0;
  }

  display: flex;
  flex-direction: column;

  .row.item {
    display: flex;
    align-items: flex-end;
    padding: 2px 5px 2px 0;

    .rowTitle {
      width: fit-content;
      min-width: fit-content;
      margin-bottom: 0;

      flex: unset;
      padding-left: 1rem;

      input {
        max-width: 50px;
        border-bottom: 1px solid $_blue-dark;
        height: 20px;
        margin-bottom: 0;
        padding: 0;
        background: transparent;
        text-align: center;
      }
    }

    &:hover {
      background-color: #eff5e6;
    }
  }
}

.deleteItem {

  &.deleteSelectedProject {
    width: 17px;
    height: 17px;
    padding: 0;
    border: none;

    svg {
      fill: salmon;
    }
  }

  cursor: pointer;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  background-color: white;
  width: 22px;
  height: 22px;
  font-weight: normal;
  font-size: 11px;

  border: 1px solid #89c732;
  color: #89c732;

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }

  &:hover {
    color: lightgray;
  }

  &:hover {
    color: gray;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    width: 100%;
    height: 100%;
  }

  &:hover {
    background-color: #fff;
  }
}

.projectRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 90%;

  &>div:first-child {
    padding-left: 10px;
  }
}

.projectValuesRow {
  .rowInput {
    margin-right: 10px;

    &:first-of-type {
      margin-right: 20px;
    }

    input {
      text-align: left;
      font-size: 90%;
    }
  }
}

.noArrowsNumberInput input {
  border-radius: 0;

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type="number"] {
    -moz-appearance: textfield;
  }
}

.sectorContainerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .rowTitle,
  .sectors {
    width: 50%;
  }

  .rowInput2 {
    padding-left: 12px !important;
  }
}

.editModeProjectRow {
  td:last-child {
    border-right: 1px solid lightgray;
  }
}

.addProjectButtonContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  svg {
    fill: #8ac734;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }

  p {
    margin: 0;
  }

  div {
    button {
      height: 100%;
    }
  }
}

.budgetLineRow {
  td {
    background-color: #e4f0d4;

    .budgetLineContentWrapper {
      padding: 13px 15px;
      display: flex;
      justify-content: space-between;
    }

    p {
      margin: 0;
    }

    position: relative;
    padding: 0;
  }

  td:first-child {
    font-size: 18px;
    text-transform: capitalize;
  }

  td:nth-child(2) {
    .typeSelectContainer {
      position: absolute;
      width: fit-content;
      right: 0;
      height: fit-content;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      justify-content: space-between;

      div {
        min-width: 30px;

        &.onlyChild {
          width: unset;
        }

        width: 45%;
        margin-right: 15px;
        padding: 2px;

        &.selected {
          background-color: #261f63;
          color: white;
        }

        &.notSelected {
          background-color: transparent;
        }

        cursor: pointer;
        border-radius: 3px;

        p {
          margin: 0;
          text-align: center;
        }
      }
    }

    .budgetLineSelect {
      position: absolute;
      width: 20%;
      right: 0;
      height: fit-content;
      top: 50%;
      transform: translateY(-50%);

      button {
        height: 100%;
      }
    }
  }

  .budgetLineInput {
    margin: 0;
    background-color: transparent;
    width: 100%;

    input {
      padding: 0;
      width: 100%;
      text-align: left;
      font-size: 18px;
    }
  }

  &:last-child {
    border-radius: 0 0 20px 20px;

    td {
      &:first-child {
        border-radius: 0 0 0 20px;
      }

      &:last-child {
        border-radius: 0 0 20px 0;
      }
    }
  }

  input[type="checkbox"] {
    accent-color: #261f63;
  }
}

.rowInput.dropDownArrowContainer {
  display: flex;
  width: fit-content;
  position: relative;

  .budgetLineSelectorContainer {
    position: relative;

    .budgetLines {
      background-color: #fbfafc;
      border: solid 2px #8ac734;
      border-radius: 10px;
      position: absolute;
      top: 100%;
      left: calc(50% + 15px);
      transform: translateX(-50%);
      padding: 5px 0;
      z-index: 1;

      .budgetLine {
        display: flex;
        padding: 5px 10px;
        cursor: pointer;

        &:hover {
          background-color: rgba(138, 199, 52, 0.2);
        }

        svg {
          position: static;
          transform: unset;
          fill: #8ac734;
          width: 20px;
          height: 20px;
        }

        p {
          width: fit-content;
          min-width: 100px;
          text-align: left;
        }
      }
    }
  }

  p {
    margin: 0;
    cursor: pointer;
    user-select: none;

    &.toggledProject {
      font-weight: bold;
      color: #8ac734;
    }
  }

  svg {
    transition: 200ms;
    cursor: pointer;
    position: absolute;
    top: 50%;
    right: -30px;
    width: 25px;
    height: 25px;
    transform: translateY(-50%);
  }
}

.checkbox {
  background-color: #89c73226 !important;
  cursor: pointer;
  width: 22px !important;
  height: 22px !important;
  border-width: 1px !important;
  border-color: #1f196099 !important;
}

.checkboxIconContainer {
  display: flex;
  flex: 1;
  align-self: stretch;
}

.hrCheckbox {
  cursor: pointer;
  padding-right: 10px;
}

.checkboxWrapper {
  display: flex;
  flex-direction: row;
  justify-content: end;
  margin: auto;
}

.checkedExpenses {
  width: 100%;
}

.checkedExpenses input {
  width: 100%;
}

.buttonCalculateWrapper {
  display: flex;
  justify-content: end;
  width: 100% !important;
}

.pickedSectorWrapper{
  display: flex;
  justify-content: flex-end;
  .projectRow.item{
    width: 50%;
    .rowInput2{
      padding: 0 !important;
      margin: 0 !important;
      margin-bottom: 10px !important;
      padding-left: 12px !important;
    }
    .deleteItem{
      min-width: 22px;
      min-height: 22px;
      max-width: 22px;
      max-height: 22px;
    }
  }
}