@import "styles/_variables.scss";

.container {
	font-family: "Roboto";
	height: 100vh;
	background-color: $_bg-main;
	color: $_blue-dark;
	display: grid;
	grid-template-columns: 1fr 1fr;
	width: 100vw;
	overflow: auto;
	min-height: fit-content;
	min-width: fit-content;

	.left {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		justify-content: center;

		img {
			width: 100%;
			min-width: 500px;
			max-width: 750px;
			padding: 10rem;
		}
	}

	.right {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
	}
}

.form {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 1.5rem;
	border-radius: 10px;
	margin-left: 0;
	width: 60%;
	max-width: 500px;
	padding-left: 3rem;

	.card {
		background-color: $_bg-form;
		box-shadow: none;
		margin: 0;
		padding: 2rem;
	}
	.cardContentClass {
		> * {
			margin: 2rem;
			margin-top: 0;
		}
	}
	.header {
		display: flex;
		flex-direction: column;
		align-items: center;

		* {
			white-space: nowrap;
			padding: 5px;
			margin: 0;
		}
		h4 {
			font-size: 22px;
			font-weight: 600;
		}
		p {
			font-size: 16px;
		}
	}
	.signInButton {
		font-size: 16px;
		margin: 1rem;
		background-color: #89c732;
		border-color: #89c732;
		border-radius: 4px;
        margin-top: -20px;
		&:hover {
			border-color: $_green-light !important;
			opacity: 0.85;
		}
	}

	.rememberPassword {
		cursor: pointer;
	}

	.loginInput {
		box-shadow: 0 3px 6px #2c28281c;

		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			padding-left: 1rem;
		}
	}

	.loginMessage {
		padding: 0.5rem 1rem;
		border: 1px solid $_red;
		border-radius: $_global-radius;
		color: $_red;
		margin-bottom: 5px;
		margin-top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 400;
		font-size: 14px;
		font-family: "Montserrat";
	}

	.loaderWrapper {
		height: 50px;
		width: 123px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 1rem;
	}
}

.buttonStyle {
	background-color: $_green-light !important;
	color: #fff !important;
	border-color: transparent !important;
}

.signUpLink {
	display: flex;
	font-weight: 700;
	font-size: 15px;
	padding: 15px;
	.link {
		color: $_green-light;
		padding-left: 5px;
		text-decoration: none;
	}
}

.forgotPassword {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.forgotPassword {
	font-size: 15px;
	text-decoration: none;
	color: $_blue-dark;
	cursor: pointer;
}

.forgotPassword:hover{
	color: #afaad8;
}

.inputIcon {
}

@media screen and (max-width: 1010px) {
	.container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		padding: 2rem;
		width: 100%;
		.left img {
			padding: 3rem;
			min-width: 50%;
		}
		.right {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: row;
		}
		form {
			padding: 0;
			margin: 0;
			width: 100%;
		}
	}
}

.eyeSvg{
	color: #2B2467;
	margin-right: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 1px;
	font-size: 18px;
	cursor: pointer;
    position: absolute;
    right: 15px;
	width: 20px;
	height: 20px;
}