
.notificationsContainer{
  color: #261f63;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 50%;
  box-shadow: 0 2px 6px #2C28281C;
  border-radius: 10px 10px 0 0;
  padding: 20px 10px 10px 30px;
}

.titleSection{
  font-size: 21px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;

  div:not(.markAllContainer) {
    padding: 0;
  }
  

  svg {
    color: #261f63;
  }

  p {
    margin: 0;
  }
}

.notificationTabSection {
  width: 100%;

  li {
    width: 12.5rem;
  }

  ul {
    font-size: 18px;
  }
}

.notificationParent{
  display: flex;
  justify-content: center;
  height: 100%;
}

.notificationEntriesParent{
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.infiniteScrollContainer{
  flex: 1;
  overflow: auto;
}

.notificationSubTitle{
  font-weight: bold;
  font-size: 18px;
  margin-top: 15px;
}

.pageContainer{
  padding: 1.5rem 3.25rem 0 3.25rem!important;
}

.markAllContainer{
  z-index: 2;
  cursor: pointer;
  top: calc(100% + 3px);
  right: 15px;
  background-color: white;
  position: absolute;
  border-radius: 15px;
  box-shadow: 0 2px 6px #2C28281C;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 35px;
  font-size: 18px;
  font-weight: bold;

  &:hover{
    z-index: 2;
    background-color: #d3d3d336;
  }
}

.noNotificationContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  overflow: auto;
}
