@import "styles/_variables.scss";

.commonHeader {
    color: $_blue-dark;
    padding: 0;

	&.alignedButtons {
		display: flex;
		align-items: center;
		.title {
			flex: 1;
		}
	}
    .title {
        font-weight: bold;
        font-size: 28px;
        margin-top: 0;
        margin-bottom: 5px;
        color: inherit;
    }
    .buttonContainer {
        display: flex;
        justify-content: flex-end;
        font-size: 20px;
        button {
            min-height: unset;
            border: none;
            color: #261f63;
            background-color: #fff;
            box-shadow: 0 3px 6px #2c28281c;
            padding: 10px 20px;
            margin-bottom: 0;
        }
        
    }
}