@import "styles/_variables.scss";

$_22px: 1.375rem;
$_8px: 0.5rem;

.modalInput {
  border: 1px solid #1f196099;
  background-color: #89c7321a;
  border-radius: 5px;
  margin: 0;
  width: 100px;
  font-size: $_22px;
  height: 42px;

  input {
    width: 100%;
    padding: $_8px;
    border-radius: 0;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
}

.modalTitle {
  font-size: 25px;
  color: $_green-light;
}
