@import "styles/_variables.scss";

$_8px: 0.5rem;
$_10px: 0.625rem;
$_12px: 0.75rem;
$_16px: 1rem;
$_17px: 1.0625rem;
$_18px: 1.125rem;
$_20px: 1.25rem;
$_22px: 1.375rem;
$_24px: 1.5rem;
$_30px: 1.875rem;
$_50px: 3.125rem;
$_90px: 5.625rem;
$_100px: 6.25rem;
$_130px: 8.125rem;
$_202px: 12.625rem;

.modalMainStyle {
  min-width: unset !important;
  width: calc(90% - 200px);
  left: 140px;
  max-width: 1200px;
  padding: 1.5rem !important;
  padding-bottom: 3rem !important;

  &.vacations {
    width: 700px;
    min-width: fit-content !important;

    td:last-child {
      padding-right: 3rem;
    }
  }

  &.sizeMedium {
    max-width: 700px;
  }

  &.sidebarCollapsed {
    width: calc(90% - 60px);
    left: 58px;
  }

  .modalBody {
    display: flex;
    width: 100%;

    table {
      border-collapse: collapse;
      width: 100%;
    }

    tr td {
      padding: 1.5rem 1.5rem 0 4.5rem;
    }

    .text {
      white-space: nowrap;
    }

    .box {
      input {
        width: fit-content;
        max-width: 80px;
      }
    }

    .inputCol {
      max-width: 150px;
    }

    .inputCell {
      .box {
        width: fit-content;
        max-width: fit-content;
      }
    }

    .secondLabel {
      padding-left: 0;

      .text {
        text-align: center;
      }
    }

    .footerAlert {
      color: $_red;
      padding: 3rem 3rem 0.5rem 4.5rem;
      font-size: 15px;
      width: 700px;
    }

    &.taxModal {
      tbody {
        td:last-child {
          padding-right: 3rem;
        }
      }
    }

    &.overtime {
      .inputCol {
        max-width: 290px;
        width: 300px;
      }
    }

    .title {
      white-space: nowrap;
      display: flex;
      align-items: center;
      padding-right: 1.5rem;
    }

    .modalInput {
      border: 1px solid #1f196099;
      background-color: #89c7321a;
      border-radius: 5px;
      margin: 0;
      width: 100px;
      font-size: 20px;
      height: 42px;

      input {
        width: 100%;
        padding: 8px;
        border-radius: 0;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &[type="number"] {
          -moz-appearance: textfield;
        }
      }
    }

    .tabsWrapper {
      display: flex;

      .col {
        flex: 1;

        ul {
          width: 100%;
        }

        li {
          width: 50%;
        }
      }

      .col:first-child {
        padding-left: 3rem;
      }

      .col:last-child {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

      .col:last-child .row {
        padding-right: 3rem;
      }
    }

    .actionsContainer {
      width: fit-content;
      position: absolute;
      top: calc(1.5rem + 10px);
      right: 55px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2px;

      svg {
        &:first-child {
          width: 20px;
          height: 20px;
          color: #89c732;
          margin-right: 20px;
          cursor: pointer;
        }

        &:last-child {
          width: 16px;
          height: 16px;

          g {
            path {
              fill: #261f63 !important;
            }
          }
        }
      }

      & + .sectorTable {
        width: 100%;

        .sectorTableHeader {
          width: 100%;
          background-color: #1f19600f;
          padding: 27px 72px;

          p {
            margin: 0;
            color: #261f63;
            font-weight: bold;
            font-size: 23px;
          }
        }

        .sectorTableContentBody {
          padding: 25px 72px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          height: 330px;
          overflow-y: auto;

          div {
            width: 90%;
            max-width: unset;
            margin-bottom: 10px;

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }

  .modalPrintClass {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }

  .headerClass {
    padding: 0;
  }

  .row {
    display: flex;
    justify-content: flex-start;
  }

  .col1 {
    flex: 1;
    padding: 0.5rem 1rem;
  }

  .col2 {
    flex: 2;
    padding: 0.5rem 1rem;
  }

  .box {
    margin-left: 61px;
    box-shadow: 2px 8px 16px #1f196014;
    border-radius: 16px;
    padding: 1.5rem 1.25rem;
    max-width: 300px;
    min-width: fit-content;
    margin: 0;
    background-color: #fff;

    input {
      padding: 0;
    }
  }

  .headerBar {
    .cell {
      background-color: #1f19600f;
      padding: 1.5rem 4.5rem 1.5rem;
      font-size: 18px;
      text-align: left;
    }
  }

  .setupModalFooter {
    button:last-child {
      margin-right: 1.5rem !important;
    }
  }
}

.modalContent {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  margin-top: -2px;
  margin-bottom: -20px;

  .inputContainer {
    flex: 1 1 33%;
    padding: 1rem 3rem 1rem 0;
    // width: 53%;
    min-width: fit-content;

    .modalInput {
      background-color: transparent;
      margin: 0;
    }

    .searchBox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      outline: none;
      border: 0;
      padding-right: 0;

      &::placeholder {
        color: $_blue-dark;
        opacity: 0.8;
      }
    }

    input {
      padding: 0.5rem;
      font-weight: 500;
      font-size: 18px;
    }

    .iconContainer {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  .inputContainer2 {
    flex: 1 1 33%;
    padding: 1rem 3rem 1rem 0;
    width: 33%;
    min-width: fit-content;
    margin-right: 34%;

    .modalInput {
      background-color: transparent;
      margin: 0;
    }

    .searchBox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      outline: none;
      border: 0;
      padding-right: 0;

      &::placeholder {
        color: $_blue-dark;
        opacity: 0.8;
      }
    }

    input {
      padding: 0.5rem;
      font-weight: 500;
      font-size: 18px;
    }

    .iconContainer {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  .inputBox {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    background-color: #fff;
    box-shadow: 2px 8px 16px #18327314;
    border-radius: 16px;
    max-width: 270px;
  }

  button.inputBox {
    background-color: #fff !important;
    box-shadow: 2px 8px 16px #18327314 !important;
    margin: 0;
    font-size: 18px;

    width: 100%;
    display: flex;
    justify-content: flex-start;

    svg {
      margin-right: 1rem;
    }
  }

  .quickbooksInput {
    pointer-events: none;
    padding-left: 1rem;

    input {
      padding-left: 0;
    }
  }

  .companiesContainer {
    width: 100%;
    margin-top: 0.5rem;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 5px 0;
    width: 270px;

    .company {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px 5px 2px 8px;

      &:hover {
        background-color: #eff5e6;
      }
    }
  }

  .deleteItem {
    cursor: pointer;
    border-radius: 50%;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    background-color: white;
    width: 22px;
    height: 22px;
    font-weight: normal;
    font-size: 11px;
    border: 1px solid #89c732;
    color: #89c732;

    svg {
      width: 100%;
      height: 100%;
      fill: currentColor;
    }

    &:hover {
      color: lightgray;
    }

    &:hover {
      color: gray;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      width: 100%;
      height: 100%;
    }

    &:hover {
      background-color: #fff;
    }
  }

  .searchCompaniesResult {
    position: absolute;
    width: 100%;

    .companiesContainer {
      max-height: 100px;
      overflow-y: auto;
      background-color: #fff;
      box-shadow: 0px 2px 10px #00000027;
      width: 270px;
    }

    .company {
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(128, 128, 128, 0.062);
      }

      &.selected {
        background-color: #eff5e6;
        border-bottom: none;
      }
    }
  }
}

.modalHeader {
  width: 100%;

  .modalTitle {
    color: $_green-light;
    font-size: 20px;
    font-weight: 500;

    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 2rem;

    padding: 10px 2rem 1.5rem 2rem;
  }

  .icon {
    margin-left: 1rem;
    cursor: pointer;

    svg {
      width: 22px;
      height: 22px;
    }
  }

  .titleIconWrapper {
    display: flex;
    justify-content: space-between;
    margin-right: 36px;

    .modalTitle {
      color: $_green-light;
      font-size: 20px;
      font-weight: 500;

      padding-left: 1.5rem;
      padding-right: 1.5rem;
      padding-bottom: 2rem;

      padding: 10px 2rem 1.5rem 2rem;
    }

    .icon {
      margin-left: 1rem;
      cursor: pointer;
      padding-left: 1.5rem;

      svg {
        margin-top: 8px;
        width: 22px;
        height: 22px;
      }
    }
  }
}

@media screen and (max-width: 970px) {
  .inputBox {
    max-width: unset !important;
  }

  .inputContainer {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.eyeSvg {
  color: #2b2467;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  font-size: 18px;
  cursor: pointer;
}

.tableBody {
  .tableRow {
    .bodyCell {
      padding: 0.75rem 1.25rem !important;
      font-weight: 400;
      padding: 8px 15px;

      &.bold {
        font-weight: 600;
      }

      &.iconCell {
        svg {
          color: $_blue-dark;
          width: 25px;
          height: 25px;
        }

        padding-left: 3rem;
      }

      .sectorType {
        margin-top: 12px;
        color: #261f6399;
        font-size: 18px;
        font-weight: 500;
      }
    }

    &:not(:last-child) {
      border-bottom: 2px solid #f0f0f0;
    }

    .text {
      font-size: 19px;
      font-weight: 400;
      color: #261f63;
    }
  }

  .userNameCell {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img,
    .image {
      width: 50px;
      min-width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .nameContainer {
      margin-left: 1rem;
      cursor: pointer;
    }
  }
}

.pencilIcon {
  display: flex;

  :first-child {
    margin-right: 0.3rem;
  }

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: $_red;
  }
}

.search {
  margin-bottom: 21px;
  display: flex;
  width: 100%;
  justify-content: end;

  .searchInputContainer {
    &.customSearchInput {
      position: absolute;
      top: 25px;
      right: 250px;
    }

    padding-right: 26px !important;
    border-radius: 10px !important;
    background: #e3f0d2;
    opacity: 0.5;
    opacity: 1;
    width: 20%;

    input {
      width: 100%;
    }
  }
}

.projectList {
  padding: 0 30px;
}

.search.projectList {
  margin-top: 5px;
  padding-right: 0;
}

.headerButton {
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  width: 18%;
  box-shadow: 0 3px 6px #2c28281c;
  border: none;
  align-self: end;
  margin-right: 0;

  &.customButton {
    position: absolute;
    top: 20px;
  }
}

.headerButtonGenerate {
  font-weight: 500;
  margin-top: 0;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  width: 18%;
  box-shadow: 0 2px 6px #2c28281c;
  border: none;
  align-self: end;
  margin-top: 8px;
  margin-left: 186px;
}

.headerTitle {
  padding-right: 3rem;
  margin-bottom: 0;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;

  svg {
    fill: #89c732;
    height: 20px;
    width: 20px;
  }
}

.modalContent {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .inputContainer {
    flex: 1 1 33%;
    padding: 1rem 3rem 1rem 0;
    width: 33%;
    min-width: fit-content;

    .modalInput {
      background-color: transparent;
      margin: 0;
    }

    .modalInput2 {
      background-color: transparent;
      margin: 0;

      input::placeholder {
        font-size: 12.5px !important;
      }
    }

    .searchBox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      outline: none;
      border: 0;
      padding-right: 0;

      &::placeholder {
        color: $_blue-dark;
        opacity: 0.8;
      }
    }

    input {
      padding: 0.5rem;
      font-weight: 500;
      font-size: 18px;
    }

    .iconContainer {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  .inputBox {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    background-color: #fff;
    box-shadow: 2px 8px 16px #18327314;
    border-radius: 16px;
    max-width: 270px;
  }

  button.inputBox {
    background-color: #fff !important;
    box-shadow: 2px 8px 16px #18327314 !important;
    margin: 0;
    font-size: 18px;

    width: 100%;
    display: flex;
    justify-content: flex-start;

    svg {
      margin-right: 1rem;
    }
  }

  .quickbooksInput {
    pointer-events: none;
    padding-left: 1rem;

    input {
      padding-left: 0;
    }
  }

  .companiesContainer {
    width: 100%;
    margin-top: 0.5rem;

    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 5px 0;
    width: 270px;

    .company {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px 5px 2px 8px;

      &:hover {
        background-color: #eff5e6;
      }
    }
  }

  .deleteItem {
    cursor: pointer;
    border-radius: 50%;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    background-color: white;
    width: 22px;
    height: 22px;
    font-weight: normal;
    font-size: 11px;
    border: 1px solid #89c732;
    color: #89c732;

    svg {
      width: 100%;
      height: 100%;
      fill: currentColor;
    }

    &:hover {
      color: lightgray;
    }

    &:hover {
      color: gray;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      width: 100%;
      height: 100%;
    }

    &:hover {
      background-color: #fff;
    }
  }

  .searchCompaniesResult {
    position: absolute;
    width: 100%;

    .companiesContainer {
      max-height: 100px;
      overflow-y: auto;
      background-color: #fff;
      box-shadow: 0px 2px 10px #00000027;
      width: 270px;
    }

    .company {
      cursor: pointer;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(128, 128, 128, 0.062);
      }

      &.selected {
        background-color: #eff5e6;
        border-bottom: none;
      }
    }
  }
}

.inputContainer {
  flex: 1 1 33%;
  padding: 1rem 3rem 1rem 0;
  width: 33%;
  min-width: fit-content;

  .modalInput {
    background-color: transparent;
    margin: 0;
  }

  .searchBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    outline: none;
    border: 0;
    padding-right: 0;

    &::placeholder {
      color: $_blue-dark;
      opacity: 0.8;
    }
  }

  input {
    padding: 0.5rem;
    font-weight: 500;
    font-size: 18px;
  }

  .iconContainer {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}

.deleteCompanyMessage {
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  color: #261f63;
  margin-bottom: 20px;
  padding-left: 30px;
}

.addProjectCustom {
  width: 100%;
  padding: 0 2rem 0 2rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  .inputContainer {
    width: 100%;
  }
}

.allCheckboxes {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  padding: 0 15px;
  margin-left: 18px;
  min-height: 42px;
  align-items: center;

  .allCheckboxesText {
    font-weight: 500;
    font-family: "Roboto";
    color: #261f63;
  }
}

.checkbox {
  background-color: #89c73226 !important;
  cursor: pointer;
  width: 20px !important;
  height: 20px !important;
  border-width: 1px !important;
  border-color: #1f196099 !important;

  label {
    justify-content: center;
  }
}

.codeWrapper {
  display: flex;
  margin-top: 26px;
  margin-left: 33px;

  position: relative;
  bottom: 12px;

  left: 20%;

  &.securityCodeInput {
    margin: 0;
    bottom: 0;
    left: 0;
    margin-left: 32px;
  }

  .titleClass {
    margin-top: 10px;
    font-weight: 500;
    padding-right: 25px;
  }

  .modalInput {
    border: 1px solid #1f196099;
    background-color: #89c7321a;
    border-radius: 5px;
    margin: 0;
    min-width: 155px;
    font-size: $_22px;
    height: 45px;
    margin-left: 21px;

    input {
      width: 100%;
      padding: $_8px;
      border-radius: 0;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
}

.copyContainer {
  margin-left: 15px;
  font-family: "Roboto";
  font-weight: 500;
  color: #89c732;
  cursor: pointer;
}

.trashIconWrapper {
  display: flex;
  justify-content: space-between;

  .inputCustom {
    margin: 0 !important;

    input {
      min-width: 300px;
    }
  }

  .box {
    input {
      min-width: 300px;
    }
  }

  .boxContainer {
    input {
      width: fit-content;
      max-width: 80px;
    }
  }
}

.trashCan {
  margin-top: 25px;
  color: rgb(221, 91, 67) !important;
  font-size: 19px;
  cursor: pointer;
}

.row {
  display: flex;
  align-items: center;
  width: 100%;
}

.customSelect {
  svg {
    height: 25px;
    width: 25px;
  }

  .inputClassName,
  input {
    max-width: 0 !important;
    max-height: 0;
    padding: 0;
    border: 0;
  }
}

.customSelect1 {
  margin-top: -10px;

  svg {
    height: 25px;
    width: 25px;
  }

  .inputClassName,
  input {
    max-width: 0 !important;
    max-height: 0;
    padding: 0;
    border: 0;
  }
}

.customSelect3 {
  padding-left: 25px;

  svg {
    height: 25px;
    width: 25px;
  }

  .inputClassName,
  input {
    max-width: 0 !important;
    max-height: 0;
    padding: 0;
    border: 0;
  }
}

.rowInput {
  input {
    border-bottom: 1px solid #261f6333;
    padding: 3px 0;
    flex: 1;
  }
}

.boxSelect {
  box-shadow: 2px 8px 16px #1f196014;
  border-radius: 16px;
  padding: 1.5rem 1.25rem;
  width: 250px;
  height: 30px;
  min-width: fit-content;
  margin: 0;
  background-color: #fff;

  input {
    padding: 0;
  }
}
.searchSelectContainer {
  width: 280px;
}
.box2 {
  text-align: center;
  margin-left: 40px !important;
  box-shadow: 2px 8px 16px #1f196014;
  border-radius: 16px;
  padding-top: 12.5px;
  width: 280px;
  min-width: fit-content;
  min-height: 45px;

  background-color: #fff;

  input {
    padding: 0px;
  }
}

.inputCustom1 {
  margin: 0 !important;
}

.inputCustom1 input {
  text-align: center !important;
}

.selectCustom1 {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}

.tableContainerQB {
  padding: 10px;
}

.tableContainerQB {
  max-height: 500px;
  overflow-x: hidden;
}

.tableBodyQuickbooks {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
}

.kontoEdit {
  display: flex;
  justify-content: space-between;
  margin: auto;
  align-items: center;
}

.agreementRow {
  display: flex;
  align-items: center;
  .agreementText {
    padding-left: 0.75rem;
  }

  .checkbox {
    cursor: pointer;
    width: 24px !important;
    height: 24px !important;
    border-width: 1px !important;
    border-radius: 50%;
    overflow: hidden;
  }

  .checkboxIconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    align-self: stretch;
    background-color: #89c732;
  }
}

.qbContainer {
  display: flex;
  margin-top: 10px;

  .qbCheckbox {
    padding-left: 5px;
    margin-top: 2px;
    cursor: pointer;
  }

  .qbCheckboxText {
    padding-left: 10px;
    font-size: 18px;
    font-family: "Roboto";
    font-weight: 500;
    color: #89c732;
  }
}
td.checkboxContainer {
  padding: 0 !important;
  display: flex;
  align-items: center;
  min-width: 20px;
}
.accountRow {
  min-width: 100% !important;
  display: flex;
  justify-content: space-between;
  height: 50px;
  margin-top: 15px;
  td {
    padding: 0 !important;
    &:nth-child(2) {
      margin-left: 20px;
    }
    &:nth-child(3) {
      margin-left: 30px;
    }
    div {
      margin: 0 !important;
    }
    .boxSelect {
      width: 280px !important;
      height: 45px !important;
      padding: 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.editTrashContainer {
  .trashCan {
    margin: 0 5px !important;
  }
}

.savePaymentAccountWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  .selectPaymentAccount {
    width: 50%;
  }
  .savePaymentAccountButton {
    width: 25%;
    margin: 0;
  }
}
