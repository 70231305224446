.spinner {
	width: 40px;
	height: 40px;
	position: relative;
}

.blueDot {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}

.greenStrip::after {
	content: "";
	position: absolute;
	background: conic-gradient(#89c732 25%, transparent 0 50%);
	-webkit-mask-image: radial-gradient(transparent 50%, #89c732 50%);
	border-radius: 50%;
	width: 100%;
	height: 100%;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%) rotate(-45deg);
	animation: rotateAnimation 1s linear infinite;
}

.blueDot::before {
	content: "";
	display: block;
	margin: 0 auto;
	width: 8%;
	height: 14%;
	background-color: #1f1960;
	border-radius: 20px;
	animation-name: fadeDot;
	animation-duration: 1s;
	animation-iteration-count: infinite;
	animation-direction: normal;
}

.blueDot:nth-of-type(1) {
	transform: rotate(45deg);
}
.blueDot:nth-of-type(2) {
	transform: rotate(90deg);
}
.blueDot:nth-of-type(3) {
	transform: rotate(135deg);
}
.blueDot:nth-of-type(4) {
	transform: rotate(180deg);
}
.blueDot:nth-of-type(5) {
	transform: rotate(225deg);
}
.blueDot:nth-of-type(6) {
	transform: rotate(270deg);
}
.blueDot:nth-of-type(7) {
	transform: rotate(315deg);
}
.blueDot:nth-of-type(8) {
	transform: rotate(360deg);
}

/* Before */
.blueDot:nth-of-type(1):before {
	animation-delay: 0;
}
.blueDot:nth-of-type(2):before {
	animation-delay: 0.3095s;
}
.blueDot:nth-of-type(3):before {
	animation-delay: 0.416s;
}
.blueDot:nth-of-type(4):before {
	animation-delay: 0.5225s;
}
.blueDot:nth-of-type(5):before {
	animation-delay: 0.619s;
}
.blueDot:nth-of-type(6):before {
	animation-delay: 0.7255s;
}
.blueDot:nth-of-type(7):before {
	animation-delay: 0.832s;
}
.blueDot:nth-of-type(8):before {
	animation-delay: 0.9385s;
}

@keyframes fadeDot {
	0% {
		background-color: #1f1960;
	}
	100% {
		background-color: #1f196002;
	}
}

@keyframes rotateAnimation {
	from {
		transform: translate(-50%, -50%) rotate(0deg);
	}
	to {
		transform: translate(-50%, -50%) rotate(360deg);
	}
}
