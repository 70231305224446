@import "styles/_variables.scss";

.form {
	display: flex;
	align-items: center;
	flex-direction: column;
	padding: 1.5rem;
	border-radius: 10px;
	margin-left: 0;
	width: 30%;
	padding-left: 2rem;
    height: fit-content;
    box-shadow: white ;
    background-color: white;
    box-shadow: 5px 10px 8px 10px #8888882c;
    max-height: 60vh;
    overflow-y: auto;
	.card {
		background-color: transparent;
		box-shadow: none;
        width: 100%;
        transition: 200ms;
        &:not(:last-child){
            border-bottom: solid 2px #F6F9FC;
        }
        border-radius: 0;
        margin: 20px 0;
        display: flex;
        align-items: center;
        &:hover{
            border-radius: 5px;
            background: #F6F9FC;
            p{
                font-weight: bold;
            }
        }
        img{
            width: 40px;
            height: 40px;
            margin: 0;
            border: solid 1px #261f63;
            border-radius: 50%;
			cursor: pointer;
        }
        p{
            margin: 0;
            height: fit-content;
            padding: 0 20px;
            color: #261f63;
            transition: 200ms;
			cursor: pointer;
        }
        .cardContentClass{
            display: flex;
            align-items: center;
            justify-content: start;
            width: 100%;
            padding-left: 30px;
        }
	}
	.cardContentClass {
		> * {
			margin: 2rem;
			margin-top: 0;
		}
	}
	.header {
		display: flex;
		flex-direction: column;
		align-items: center;

		* {
			white-space: nowrap;
			padding: 5px;
			margin: 0;
		}
		h4 {
			font-size: 22px;
			font-weight: 600;
		}
		p {
			font-size: 16px;
		}
	}
	.signInButton {
		font-size: 16px;
		margin: 1rem;
		background-color: #89c732;
		border-color: #89c732;
		border-radius: 4px;
		&:hover {
			border-color: $_green-light !important;
			opacity: 0.85;
		}
	}

	.rememberPassword {
		cursor: pointer;
	}

	.loginInput {
		box-shadow: 0 3px 6px #2c28281c;

		input:-webkit-autofill,
		input:-webkit-autofill:hover,
		input:-webkit-autofill:focus,
		input:-webkit-autofill:active {
			padding-left: 1rem;
		}
	}

	.loginMessage {
		padding: 0.5rem 1rem;
		border: 1px solid $_red;
		border-radius: $_global-radius;
		color: $_red;
		margin-bottom: 5px;
		margin-top: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 400;
		font-size: 14px;
		font-family: "Montserrat";
	}

	.loaderWrapper {
		height: 50px;
		width: 123px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 1rem;
	}
}

.title{
    color: #261F63;
    box-shadow: none;
    margin: 0;
    padding: 2rem;
    top: 90%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    font-family: "Roboto";
}

.loginWrapper{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 0 0 15vh 0;
}