@import "styles/_variables.scss";

.inputCustom {
	display: flex;
	width: 100%;
	margin: 1.5rem;
	margin-top: 0;
	background-color: #fff;
	border-radius: 4px;
	position: relative;

	.icon {
		padding: 0 10px;
		color: $_green-light;
		display: flex;
		align-items: center;
		padding: 1rem;

		svg {
			width: 25px;
			height: 25px;
		}
	}
	input {
		flex: 1;
		border: 0;
		outline: none;
		padding: 1rem 1rem 1rem 0;
		background-color: transparent;
		font-size: 16px;

		&::placeholder {
			color: $_blue-dark;
			opacity: 0.8;
		}
	}
	&:hover {
		background-color: rgba(255, 255, 255, 0.45);
	}

	.asterix {
		color: $_red;
		position: absolute;
		top: 2px;
		right: -10px;
	}

	.validationContainer {
		display: flex;
		width: 100%;
		justify-content: center;
		max-height: fit-content;

		position: absolute;
		bottom: -15px;
	}
}


.noAsterix {
	display: none;
}