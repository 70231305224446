@import "styles/_variables.scss";

.calculationsCard {
	flex: 1 1 100%;
	margin-top: 2rem;
	background-color: $_blue-dark;
	color: white;
	padding: 2rem;
	display: flex;
	height: fit-content;
	.titleClass {
		font-weight: bold;
		font-size: 21px;
		flex: 1 1 100%;
		padding: 0 2.5rem;
	}
}

.calculationsCardContent {
	width: fit-content;
	margin: auto;
	max-width: 100%;
	flex-wrap: nowrap;
	display: flex;
	flex-wrap: wrap;
	padding-left: 2rem;
	padding-right: 2rem;
}

.leftWrapper {
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2.5rem;
	flex: 1;
}

.rightWrapper {
	flex: 1;
	padding: 2.5rem;

	.card {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 1.5rem 1rem;
		margin: 0;
		.title {
			width: 100%;
			color: $_blue-dark;
			padding: 0 0 0 2rem;
			text-align: left;
		}
	}

	.numberCardWrapper {
		display: flex;
		flex-wrap: wrap;
	}

	.cardContentClass {
		display: grid;
		grid-template-columns: 1fr 1fr;
		width: 100%;
		padding-top: 25px;
	}

	.numberCard {
		width: fit-content;
	}
	.subWrapper {
		flex: 1 1 50%;
		min-width: fit-content;
		height: fit-content;
		padding: 0 2rem;
	}
	.borderRight {
		border-right: 1px solid $_green-light;
	}
	.numberCardNumber {
		font-weight: 600;
		color: $_blue-dark;
		white-space: nowrap;
		padding: 0;
	}
	.numberCardTitle {
		padding-bottom: 10px;
		color: $_green-light;
		font-weight: 500;
		white-space: nowrap;
	}

	.redColor {
		color: $_red;
	}

	.swHelper {
		height: 100%;
	}
}

.selectWrapper {
	width: 100%;
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;

	.title {
		font-size: 15px;
		color: $_green-light;
		padding: 10px 0;
		font-weight: 500;
	}
	.employerTypeSelect {
		font-size: 15px;
	}
}

.employeeTypeSelect {
	font-size: 14px;
}

.numberInput {
	position: relative;
	&::before {
		content: "\20AC";
		padding: 3px 4px 3px 3px;
		bottom: 12px;
		right: 10px;
		position: absolute;
		color: $_blue-dark;
		z-index: 0;
		opacity: 1;
	}
	input {
		padding-right: 30px;
		border-radius: 23px;
	}
}
