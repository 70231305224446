@import "styles/_variables.scss";

.configurationsPage {
  padding: 30px 40px !important;
  .headerButton {
    font-size: 18px;
    font-weight: 500;
    margin-top: 0;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    &:last-child {
      margin-right: 0;
    }
  }

  .headerTitle {
    padding-right: 3rem;
    margin-bottom: 0;
  }

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 8px;
    svg {
      fill: #89c732;
      height: 20px;
      width: 20px;
    }
  }

  .configButton {
    min-height: unset;
    padding: 15px 5%;
    box-shadow: 2px 8px 16px #1832730f;
    border: 1px solid transparent !important;
    &:hover {
      border: 1px solid #daf3b9 !important;
    }
  }

  .payrollTab {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 2rem;
    grid-column-gap: 3rem;
    .configButton {
    }

    .tenantName {
    }

    .title {
      padding-bottom: 1.5rem;
    }
  }

  .modalPayroll {
    min-width: 400px;
    padding-top: 0;
  }

  .backupTab {
    .title {
      font-size: 18px;
      font-weight: 400;
      padding-bottom: 1rem;
    }
    .buttonsWrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 2rem;
      grid-column-gap: 3rem;
    }
  }

  .tableWrapper {
    height: calc(100vh - 20.5rem);
    min-height: calc(100vh - 20.5rem);
    border-radius: 10px;
  }
}

.title {
  margin-top: 3px;
  font-size: 20px;
  font-weight: 700;
}

.nameBox {
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid lightgrey;

  .name {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 500;
    color: $_green-light;
  }

  .tenantName {
    margin-top: 20px;
    font-size: 20px;
    margin-left: 15px;
    font-weight: 500;
  }
}

.emailBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1.5rem;
  border-bottom: 1px solid lightgrey;

  .email {
    font-size: 18px;
    font-weight: 500;
    color: $_green-light;
  }

  .emailAddress {
    font-size: 20px;
    margin-left: 15px;
    font-weight: 500;
  }
}

.tableBody {
  .tableRow {
    .bodyCell {
      padding: 0.75rem 1.25rem !important;
      font-weight: 400;
      padding: 8px 15px;

      &.bold {
        font-weight: 600;
      }
      &.iconCell {
        svg {
          color: $_blue-dark;
          width: 25px;
          height: 25px;
        }
        padding-left: 3rem;
      }

      .sectorType {
        margin-top: 12px;
        color: #261f6399;
        font-size: 18px;
        font-weight: 500;
      }
    }
    &:not(:last-child) {
      border-bottom: 2px solid #f0f0f0;
    }

    .text {
      font-size: 19px;
      font-weight: 400;
      color: #261f63;
      cursor: pointer;
    }
  }

  .userNameCell {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img,
    .image {
      width: 50px;
      min-width: 50px;
      height: 50px;
      border-radius: 50%;
    }

    .nameContainer {
      margin-left: 1rem;
      cursor: pointer;
    }
  }
}

.search {
  margin-bottom: 21px;
  display: flex;
  width: 100%;
  justify-content: end;
  .searchInputContainer {
    margin-top: -9px;
    padding-right: 26px !important;
    border-radius: 10px !important;
    background: #e3f0d2;
    opacity: 0.5;
    opacity: 1;
    width: 20%;
  }
}

.pencilIcon {
  display: flex;

  :first-child {
    margin-right: 0.3rem;
  }

  svg {
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: $_red;
  }
}

.deleteCompanyMessage {
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  color: #261f63;
  margin-bottom: 20px;
}

.modalContent {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem !important;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .inputContainer {
    flex: 1 1 33%;
    padding: 1rem 3rem 1rem 0;
    width: 33%;
    min-width: fit-content;

    .modalInput {
      background-color: transparent;
      margin: 0;
    }
    .searchBox {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      outline: none;
      border: 0;
      padding-right: 0;

      &::placeholder {
        color: $_blue-dark;
        opacity: 0.8;
      }
    }

    input {
      padding: 0.5rem;
      font-weight: 500;
      font-size: 18px;
    }

    .iconContainer {
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }

  .inputBox {
    display: flex;
    align-items: center;
    padding: 1rem 1.5rem;
    background-color: #fff;
    box-shadow: 2px 8px 16px #18327314;
    border-radius: 16px;
    max-width: 270px;
  }

  button.inputBox {
    background-color: #fff !important;
    box-shadow: 2px 8px 16px #18327314 !important;
    margin: 0;
    font-size: 18px;

    width: 100%;
    display: flex;
    justify-content: flex-start;

    svg {
      margin-right: 1rem;
    }
  }

  .quickbooksInput {
    pointer-events: none;
    padding-left: 1rem;
    input {
      padding-left: 0;
    }
  }

  .companiesContainer {
    width: 100%;
    margin-top: 0.5rem;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 5px 0;
    width: 270px;
    .company {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 2px 5px 2px 8px;

      &:hover {
        background-color: #eff5e6;
      }
    }
  }

  .deleteItem {
    cursor: pointer;
    border-radius: 50%;
    padding: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0;
    background-color: white;
    width: 22px;
    height: 22px;
    font-weight: normal;
    font-size: 11px;
    border: 1px solid #89c732;
    color: #89c732;

    svg {
      width: 100%;
      height: 100%;
      fill: currentColor;
    }
    &:hover {
      color: lightgray;
    }

    &:hover {
      color: gray;
    }

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      width: 100%;
      height: 100%;
    }
    &:hover {
      background-color: #fff;
    }
  }

  .searchCompaniesResult {
    position: absolute;
    width: 100%;

    .companiesContainer {
      max-height: 100px;
      overflow-y: auto;
      background-color: #fff;
      box-shadow: 0px 2px 10px #00000027;
      width: 270px;
    }

    .company {
      cursor: pointer;
      &:not(:last-child) {
        border-bottom: 1px solid rgba(128, 128, 128, 0.062);
      }
      &.selected {
        background-color: #eff5e6;
        border-bottom: none;
      }
    }
  }
}

.inputBox {
  display: flex;
  align-items: center;
  padding: 1rem 1.5rem;
  background-color: #fff;
  box-shadow: 2px 8px 16px #18327314;
  border-radius: 16px;
  max-width: 270px;
}

@media screen and (max-width: 970px) {
  .inputBox {
    max-width: unset !important;
  }

  .inputContainer {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}

.modalMainStyle {
  min-width: unset !important;
  width: calc(90% - 200px);
  left: 140px;
  max-width: 1200px;
  padding: 1.5rem !important;

  &.sizeMedium {
    max-width: 700px;
  }

  &.sidebarCollapsed {
    width: calc(90% - 60px);
    left: 58px;
  }

  .modalBody {
    display: flex;
    width: 100%;
    table {
      border-collapse: collapse;
    }

    tr td {
      padding: 1.5rem 1.5rem 0 4.5rem;
    }

    .box {
      input {
        width: fit-content;
        max-width: 100px;
      }
    }

    .inputCol {
      max-width: 150px;
    }

    .inputCell {
      .box {
        width: fit-content;
        max-width: fit-content;
      }
    }

    .footerAlert {
      color: $_red;
      padding: 2rem 4.5rem;
      font-size: 15px;
    }
  }

  .modalPrintClass {
    margin-right: -1.5rem;
    margin-left: -1.5rem;
  }
  .headerClass {
    padding: 0;
  }

  .row {
    display: flex;
    justify-content: flex-start;
  }

  .col1 {
    flex: 1;
    padding: 0.5rem 1rem;
  }
  .col2 {
    flex: 2;
    padding: 0.5rem 1rem;
  }
  .box {
    box-shadow: 2px 8px 16px #1f196014;
    border-radius: 16px;
    padding: 1.5rem 1.25rem;
    max-width: 300px;
    min-width: fit-content;
    margin: 0;
    background-color: #fff;
    input {
      padding: 0;
    }
  }

  .headerBar {
    .cell {
      background-color: #1f19600f;
      padding: 1.5rem 4.5rem;
      font-size: 18px;
      text-align: left;
    }
  }
}

.modalHeader {
  width: 100%;
  .modalTitle {
    color: $_green-light;
    font-size: 20px;
    font-weight: 500;

    padding-left: 1.5rem;
    padding-right: 1.5rem;
    padding-bottom: 2rem;

    padding: 0 2rem 1.5rem 2rem;
  }
}

.paginationWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 0;
  font-size: 17px;

  .paginationSelect {
    border-radius: 4px;
  }
}

.previousSvg {
  width: 20px;
  height: 20px;
}
.nextSvg {
  width: 20px;
  height: 20px;
}

.customFileUpload {
  position: absolute;
  width: 80;
  height: 80;
  cursor: pointer;
  opacity: 0;
}
.positionRelative {
  position: relative;
  display: flex;
}
.infoSvg {
  z-index: 99;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  font-size: 20px;
  color: #89c732;
  width: 20px;
  height: 20px;
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
}

.eyeSvg {
  color: #2b2467;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1px;
  font-size: 18px;
}

.qbContainer {
  margin-top: 10px;
  display: flex;
  position: absolute;
  top: 83%;
  display: flex;
  left: 20px;
  margin-top: 10px;

  .qbCheckbox {
    padding-left: 5px;
    margin-top: 2px;
    cursor: pointer;
  }

  .qbCheckboxText {
    padding-left: 10px;
    font-size: 18px;
    font-family: "Roboto";
    font-weight: 500;
    color: #89c732;
  }
}
