@import "styles/_variables.scss";

$_8px: 0.5rem;
$_10px: 0.625rem;
$_12px: 0.75rem;
$_16px: 1rem;
$_17px: 1.0625rem;
$_18px: 1.125rem;
$_20px: 1.25rem;
$_22px: 1.375rem;
$_24px: 1.5rem;
$_30px: 1.875rem;
$_50px: 3.125rem;
$_90px: 5.625rem;
$_100px: 6.25rem;
$_130px: 8.125rem;
$_202px: 12.625rem;

.projectsComponent {
	.modal {
		.contentClass {
			overflow-y: unset;
		}
	} 
}

.headerButton {
	font-size: 18px;
	font-weight: 500;
	margin-top: 0;
	padding-top: 15px !important;
	padding-bottom: 15px !important;
	width: 18%;
	box-shadow: 0 3px 6px #2c28281c;
	border: none;
	align-self: end;
	margin-right: 0;
	&.customButton{
        margin-top: 32px;
	}
}

.btn {
	font-size: 16px;
	margin-bottom: 0;
	margin-right: 0;
	margin-top: 5px;
}

.submitBtn {
	min-width: 155px;
}

.headerButtonBugdetLine {
	font-size: 18px;
	font-weight: 500;
	margin-top: 0;
	padding-top: 15px !important;
	padding-bottom: 15px !important;
	width: 18%;
	box-shadow: 0 3px 6px #2c28281c;
	border: none;
	align-self: end;
	margin-right: 0;
	color: red;
    padding-right: 27px;
	height: 59px;
	border-radius: 16px;

	&.customButton{
        margin-top: 32px;
	}
}

.headerContainer{
    display: flex;

    .header {
        display: flex;
        margin-top: 33px;
    
        .arrowIcon {
            margin-top: 9px;
            margin-right: 16px;
            width: 19px;
            height: 19px;
            color: #89c732;
            cursor: pointer;
            min-width: -moz-fit-content;
            min-width: fit-content;
        }
    }

    .modalBody {
        display: flex;
        width: 100%;
    }
}

.iconContainer {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-right: 8px;
	svg {
		fill: #89c732;
		height: 20px;
		width: 20px;
	}
}

.tableContainer{
    margin-top: 46px;
	padding: 0.3rem;
}

.searchBox {
	margin-bottom: 21px;
	display: flex;
    width: 100%;
    justify-content: end;
	.searchInputContainer {
		&.customSearchInput{
			position: absolute;
			top: 25px;
			right: 250px;
		}
		padding-right: 26px !important;
		border-radius: 10px !important;
		background: #E3F0D2;
		opacity: 0.5;
		opacity: 1;
		width: 20%;
		input{
			width: 100%;
		}
	}
}

.tableBody {
	.tableRow {
		.bodyCell {
			padding: 0.75rem 1.25rem !important;
			font-weight: 400;
			padding: 8px 15px;

			&.bold {
				font-weight: 600;
			}
			&.iconCell {
				svg {
					color: $_blue-dark;
					width: 25px;
					height: 25px;
				}
				padding-left: 3rem;
			}

			.sectorType {
				margin-top: 12px;
				color: #261f6399;
				font-size: 18px;
				font-weight: 500;
			}
		}

		&:not(:last-child) {
			border-bottom: 2px solid #f0f0f0;
		}

		.text {
			font-size: 19px;
			font-weight: 400;
			color: #261F63;
		}

		.textBudget {
			font-size: 19px;
			font-weight: 400;
			color: #A6D565;
		}
	}

	.userNameCell {
		display: flex;
		justify-content: flex-start;
		align-items: center;

		img,
		.image {
			width: 50px;
			min-width: 50px;
			height: 50px;
			border-radius: 50%;
		}

		.nameContainer {
			margin-left: 1rem;
			cursor: pointer;
		}
	}
}

.pencilIcon {
	display: flex;
	
	:first-child {
		margin-right: 0.3rem;
	}

	svg {
		width: 20px;
		height: 20px;
		cursor: pointer;
		color: $_red;
	}
}

.statusContainer{
	display: flex;
	margin-left: 17px;

	.inputCircle{
		margin-left: -8px;
	}

	.circleContainer{
		width: 12px;
		height: 12px;
		background: red;
		border-radius: 50px;
		margin-top: 18px;
	}

	.delayed{
		width: 12px;
		height: 12px;
		background: #FB6340;
		border-radius: 50px;
		margin-top: 18px;
	}

	.scheduled{
		width: 12px;
		height: 12px;
		background: #11CDEF;
		border-radius: 50px;
		margin-top: 18px;
	}

	.pending{
		width: 12px;
		height: 12px;
		background: #FFD700;
		border-radius: 50px;
		margin-top: 18px;
	}

	.done{
		width: 12px;
		height: 12px;
		background: #2DCE98;
		border-radius: 50px;
		margin-top: 18px;
	}
}

.budgetContainer{
	color: red;
}

.searchWrapper{
    margin-left: auto;
    margin-right: 20px;
    width: 18%;
	position: relative;
	z-index: 3;
	margin-top: 30px;
	margin-bottom: -20px;
}

.tableContainer.projectTable{
	background-color: white;
	position: relative;
	z-index: 2;
	&::before{
		content: '';
		position: absolute;
		bottom: 100%;
		transform: translateX(-50%);
		background-color: white;
		left: 50%;
		width: 100%;
		height: 95px;
		border-radius: 20px 20px 0 0;
	}
	.tableWrapper{
		table{
			thead{
				tr{
					th{
						border-radius: 0 !important;
					}
				}
			}
		}
	}
}

.deleteCompanyMessage {
	font-size: 20px;
	font-family: "Roboto";
	font-weight: 500;
	color: #261f63;
	margin-bottom: 20px;
}

.modalHeader {
	width: 100%;
	.modalTitle {
		color: $_green-light;
		font-size: 20px;
		font-weight: 500;
	}
}

.modalInput {
	background-color: #89c7321a;
	border-radius: 5px;
	margin: 0;
	min-width: 155px;
	font-size: $_22px;
	margin-left: 21px;
	height: 31px;
	
	input {
		width: 100%;
		padding: $_8px;
		border-radius: 0;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
		&[type="number"] {
			-moz-appearance: textfield;
		}
	}
}

.inputBox {
	display: flex;
	align-items: center;
	padding: 1rem 1.5rem;
	background-color: #fff;
	box-shadow: 2px 8px 16px #18327314;
	border-radius: 16px;
	max-width: 270px;
}

.inputBoxBudgetLine {
	display: flex;
	align-items: center;
	padding: 1rem 1.5rem;
	box-shadow: 2px 8px 16px #18327314;
	border-radius: 16px;
	max-width: 270px;
	background-color: #EFF5E6;
	opacity: 1;
}

.inputContainer {
	flex: 1 1 33%;
	padding: 1rem 3rem 1rem 0;
	width: 33%;
	min-width: fit-content;

	.modalInput {
		background-color: transparent;
		margin: 0;
	}

	input {
		padding: 0.5rem;
		font-weight: 500;
		font-size: 18px;
	}

	.iconContainer {
		cursor: pointer;
		display: flex;
		align-items: center;
	}

	&:first-child{
		margin-right: 69px;
	}
}

.addProjectContainer{
	display: flex;
}

.projectStatusContainer{
	display: flex;
}

.hrLine{
	width: 100%;
    border: 1px solid #837DC1;
    opacity: 0.4;
}

.budgetLineContainer{
	display: flex;
}

.inputsContainer{
	max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
}

.budgetLineInfo{
	color: #172B4D;
	font-size: 17px;
	margin-top: 0;
	margin-bottom: 7px;
	width: fit-content;
	position: relative;
}

.projectsTableRow{
	position: relative;
	
	&.tableRowOpened{
		.budgetLineInfo{
			color: #172B4D;
		}
	}

	&.tableRowClosed{
		.budgetLineInfo{
			color: #89C732;
			font-weight: 500;
		}
	}
}

.projectsToggleDiv{
	position: absolute;
	width: 90%;
	height: 90%;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
	&:hover{
		background-color: #e7f4d633;
		border-radius: 10px;
	}
}