@import "styles/_variables.scss";
.wrapper {
	display: flex;
	flex-direction: column;
	font-size: 16px;
}

.input {
	background-color: #fbfbfb;
	border: 1px solid $_green-light;
	border-radius: 10px;
	opacity: 1;
	font-size: 1.2em;
	font-weight: bold;
	padding: 0.5rem;
	
	width: 250px;
	min-width: fit-content;

	&:focus {
		outline: 2px solid $_green-light;
	}
	height: fit-content;
	min-height: 57px;
}

.text {
	font-size: 1em;
	color: $_blue-dark;
	font-weight: 500;
	padding-bottom: 0.5rem;
	white-space: nowrap;
}
