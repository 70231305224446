@import "styles/_variables.scss";

.employeeAttachmentsContainer {
	border: 1px solid lightgray;
	border-radius: 10px;
	padding: 15px;
	padding-bottom: 10px;
	.title {
		color: $_green-light;
		font-weight: 500;
		display: flex;
		justify-content: flex-start;
		font-size: 18px;
	}

	.row {
		border: 1px solid rgba(211, 211, 211, 0.589);
		padding: 4px;
		margin-bottom: 5px;
		display: flex;
		border-radius: 6px;
	}

	.content {
		max-height: 95px;
		margin-bottom: 5px;
		overflow-y: auto;
		padding-right: 2px;
	}

	.button {
		font-size: 13px;
		padding: 5px 15px;
		min-height: unset;
		font-weight: normal;
		border-radius: 8px;
	}

	.footer {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
	.nameWrapper {
		flex: 1;
		display: flex;
		align-items: center;
	}
	.name {
		font-size: 14px;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		color: $_blue-dark;
		text-decoration: none;
		width: fit-content;
		text-align: left;

		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 195px;
		display: block;
		min-width: 100%;
		height: fit-content;
		white-space: nowrap;

		&:hover {
			opacity: 0.75;
			text-decoration: underline;
		}
	}
	.removeItem {
		display: flex;
		svg {
			width: 16px;
			height: 16px;
			border-radius: 50%;
			border: 1px solid $_blue-dark;
			cursor: pointer;
		}
	}
}
