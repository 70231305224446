@import "styles/_variables.scss";

$_8px: 0.5rem;
$_10px: 0.625rem;
$_12px: 0.75rem;
$_16px: 1rem;
$_17px: 1.0625rem;
$_18px: 1.125rem;
$_20px: 1.25rem;
$_22px: 1.375rem;
$_24px: 1.5rem;
$_30px: 1.875rem;
$_50px: 3.125rem;
$_90px: 5.625rem;
$_100px: 6.25rem;
$_130px: 8.125rem;
$_202px: 12.625rem;

.totalCell {
  padding: 1.25rem;

  div {
    width: fit-content;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #1f196099;

    background-color: rgba(137, 199, 50, 0.10196078431372549);
    text-align: center;
  }
}

.monthlyStatistics {
  display: flex;

  .point {
    margin-right: 32px;
    margin-top: 15px;
    border-radius: 50%;
    background-color: #89c732;
    width: 15px;
    height: 15px;
  }

  .monthlyStatisticTitle {
    margin-top: 6px;
    font-size: 28px;
    color: #1f1960;
    font-weight: 500;
    white-space: nowrap;
    padding-right: 3.5rem;
  }

  .downloadIcon {
    color: #89c732;
    padding-right: 10px;
    margin-left: 30px;

    svg {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }

  .downloadText {
    font-size: 20px;
    color: #1f1960;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: flex-end;

    .dropdownText {
      margin-left: 2px;
      margin-top: -2px;
      font-weight: 500;
      padding-left: 10px;
      display: flex;
      align-items: center;
    }

    .printText {
      padding-left: 10px;
    }
  }

  .sendEmailIcon {
    color: #89c732;
    display: flex;
    align-items: center;
    margin-left: 30px;

    svg {
      transform: rotate(-90deg);
      width: 30px;
      height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .printIcon {
    stroke: #89c732 !important;
    display: flex;
    align-items: center;

    svg {
      margin-left: 30px;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }

  .iconText {
    display: flex;
    align-items: center;
  }

  .headerDate {
    font-size: 18px;
    font-weight: 500;
    color: #aeacc4;
    margin-left: 30px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
  }
}

.filterText {
  display: flex;

  .nameAndSurname {
    font-size: 18px;
    font-weight: 500;
    color: #1f1960;
  }
}

.filterBox {
  margin-top: 5px;
  display: flex;

  .surnameBoxContainer {
    width: 264px;
    height: 42px;
    padding: 6px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #89c732;
    border-radius: 8px;
    min-width: 140px;
    background-color: #fbfbfb;
    font-weight: 500;
    cursor: pointer;
  }
}

.tableContainer {
  margin-top: 1rem;

  thead th {
    border-radius: 0px !important;
  }

  .tableBody {
    .tableRow {
      .bodyCell {
        padding: 0.75rem 1.25rem;
        font-weight: 400;
        padding: 25px;

        &.bold {
          font-weight: 600;
        }

        &.iconCell {
          svg {
            color: $_blue-dark;
            width: 25px;
            height: 25px;
          }

          padding-left: 3rem;
        }

        .sectorType {
          margin-top: 12px;
          color: #261f6399;
          font-size: 18px;
          font-weight: 500;
        }
      }

      &:not(:last-child) {
        border-bottom: 2px solid #f0f0f0;
      }

      .text {
        font-size: 19px;
        font-weight: 500;
      }
    }

    .userNameCell {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img,
      .image {
        width: 50px;
        min-width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      .nameContainer {
        margin-left: 1rem;
        cursor: pointer;
      }
    }
  }
}

.filterRow {
  display: flex;
  font-size: $_18px;
  position: relative;
  margin-left: auto;
  color: $_blue-dark;
  margin-top: 3rem;
  align-items: flex-start !important;

  .filterButtons {
    margin-left: 1rem;
  }
}

.filterDescription {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  color: #261f6399;
  font-weight: 500;
  align-items: center;
  color: $_blue-dark;
}

.filterButtons {
  height: 37px;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  font-size: $_18px;
  font-weight: 500;
  min-width: 250px;
  z-index: 1041;

  .button {
    background-color: #fff;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 2rem;
    padding-left: 2rem;
    height: 100%;
    cursor: pointer;
    user-select: none;
    flex: 1 1 50%;
    width: 50%;
    min-width: fit-content;

    &:first-of-type {
      border-right: 1px solid $_green-light;
    }

    &:last-of-type {
      padding-right: 0;
      padding-left: 1.5rem;
      justify-content: space-between;
    }
  }

  .calendar {
    display: flex;
    padding: 5px 10px;
    margin-left: 1rem;
    background-color: $_green-light;
    opacity: 0.5;
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
  }
}

.filterContent {
  border: 0.5px solid $_green-light;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  position: absolute;
  top: 48px;
  right: 0;
  max-height: 55vh;
  overflow: hidden;
  min-width: 250px;
  z-index: 1041;

  .months,
  .years {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    overflow-y: auto;
    width: 50%;
  }

  .months {
    border-right: 1px solid $_green-light;
  }

  .periodItem {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $_17px;
    font-weight: 500;
    opacity: 0.5;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    user-select: none;

    &.selected,
    &:hover {
      opacity: 0.9;
      background-color: #eff5e6;
    }
  }
}

.checkboxTemplate {
  display: flex;
  padding-left: 20px;
}

.checkbox {
  background-color: #89c73226 !important;
  cursor: pointer;
  margin-right: 10px;
  width: 20px !important;
  height: 20px !important;
  border-width: 1px !important;
  border-color: #1f196099 !important;

  label {
    justify-content: center;
  }
}

.checkboxCell {
  label {
    display: flex;
    justify-content: center;
    max-width: fit-content;
    margin: auto;
  }

  text-align: center;
  padding: 10px 24px;
}

.checkboxIconContainer {
  display: flex;
  flex: 1;
  align-self: stretch;
}

.checkboxes {
  left: -100px;
}

.isCheckBoxActive {
  overflow: hidden;
  animation: widthTransition forwards 150ms;

  label {
    opacity: 0;
    animation: fadeIn forwards 150ms;
    animation-delay: 100ms;
  }
}

.checkboxIcon {
  display: flex;
  justify-content: center;
  margin-left: 15px;
}

.allCheckboxes {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  padding: 10px 15px;

  .allCheckboxesText {
    margin-left: 20px;
  }
}

.validateEmail {
  padding-left: 10px;
}

.emailWrapper {
  display: flex;
  justify-content: center;
  margin: auto;
}

.sendEmailWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 300px;
  height: 130px;
  overflow-y: unset !important;
}

.emailBox {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 20px;

  input {
    width: 100px;
    background-color: #89c7321a;
    border: 1px solid #1f196099;
    padding: 0px 10px;
    margin-right: 20px;
    height: 42px;
    border-radius: 5px;
  }
}
