.checkbox {
	background-color: #89c73226 !important;
	cursor: pointer !important;
	width: 22px !important;
	height: 22px !important;
	border-width: 1px !important;
	border-color: #1f196099 !important;
}

.checkboxCell {
	label {
		display: flex;
		justify-content: center;
		max-width: fit-content;
		margin: auto;
	}
	text-align: center !important;
	padding: 8px !important;
	padding-right: 0!important;
}
.checkboxIconContainer {
	display: flex;
	flex: 1;
	align-self: stretch;
}
