.inputField {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 5px;
    border: none;

    input {
        color: #1f1960;
        background-color: #fff;
        padding: 2px 8px;
        height: 40px;
        padding-left: 20px;
        font-size: 15px;
        &:focus-visible {
            outline: none;
        }
       
    }
    label {
        padding: 10px 0;
        font-size: 15px;
        font-weight: 500;
    }
}