@import "styles/_variables.scss";

.search {
  display: flex;
  align-items: center;
  color: white;
  flex: 1;
  height: 100%;
  padding: 15px;
  position: relative;
  z-index: 1041;
  background-color: $_bg-header;

  &.isOpen {
    background-color: #fff;
    input {
      color: $_textColor-global;
    }
  }

  .icon {
    margin: 0 15px 0 20px;
    width: 25px;
    height: 25px;
  }

  input {
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    flex: 1;
    font-weight: 300;
    font-size: 18px;
    height: 100%;
    font-family: inherit;

    &::placeholder {
      color: white;
      opacity: 1;
      border: none;
    }
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none;
    }
  }

  .loaderIcon {
    min-width: 60px;
    display: flex;
  }
  .row {
    margin-bottom: 5px;
  }
}

.searchRow{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  div:first-child{
    width: 120px;
    font-weight: bold;
    color: #89c732;
    padding: 5px; 
  }
  .row {
    padding: 5px; 
    font-weight: 500;
    width: calc(100% - 120px);
    border-radius: 5px;
    transition: 200ms;
    cursor: pointer;
    &:hover{
      background-color: #89c7321c;
    }
    &:not(:nth-child(2)){
      margin-left: 120px;
    }
  }
}