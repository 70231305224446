@import "styles/_variables.scss";

.closeButtonClass {
	top: 35px !important;
	right: 35px !important;
}

.payslipModalHeader,
.tableReportHeader {
	padding-left: 0 !important;
	padding-bottom: 0 !important;
	display: flex;
	justify-content: space-between;
	width: 100%;
	color: $_blue-dark;

	.modalTitle {
		font-size: 25px;
		color: $_green-light;
	}
	.subTitle {
		color: $_blue-dark;
		white-space: nowrap;
		display: flex;
		align-items: center;
		margin: 0 2rem;
		margin-right: 5rem;
		font-weight: 500;
		cursor: pointer;
		min-width: fit-content;
		width: 50px;

		.icon {
			margin: 0 8px;
			display: flex;
			align-items: center;
			svg {
				width: 22px;
				height: 22px;
			}
		}
		.loader {
			height: 20px;
			width: 20px;
		}
	}
}

.payslip,
.tableReport {
	padding-top: 2.5rem !important;
	padding-bottom: 0.5rem !important;
	color: $_blue-dark;
	.row {
		display: flex;
		width: 100%;
	}

	.title {
		font-size: 25px;
		font-weight: bold;
		display: flex;
		justify-content: flex-start;
		padding-bottom: 2rem;
		height: 30px;
		min-height: 30px;
	}
	.track {
		padding-bottom: 2.25rem;
	}

	.section {
		font-size: 20px;
		font-weight: 400;
		display: flex;
		justify-content: space-between;

		.cell {
			display: flex;
			&:not(:last-child) {
				padding-right: 2rem;
			}
			.cellTitle {
				color: $_green-light;
				padding-right: 0.5rem;
				white-space: nowrap;
			}
			.cellValue {
				white-space: nowrap;
			}
		}

		.boxesTitle {
			padding-bottom: 1.25rem;
			white-space: nowrap;
			font-size: 18px;
		}
		.box {
			border-radius: 16px;
			min-width: 130px;
			padding: 1.25rem 1.5rem;
			font-size: 16px;
			font-weight: 400;
			width: fit-content;
			margin-bottom: 10px;
			//min-height: 100px;
			min-height: 60px;
			white-space: nowrap;

			display: flex;
			align-items: center;
			&:not(.clean) {
				background: #ffffff;

				box-shadow: 2px 8px 16px #1f196014;
			}
			&.wideBox {
				min-width: 220px;
				background: #eeeef2;
				box-shadow: none;
			}
			&.clean {
				padding: 1.25rem 1rem;
			}
			&.hideBox {
				opacity: 0;
				pointer-events: none;
			}
		}

		.projectBox{
			border-radius: 16px;
			min-width: 100px;
			padding: 1.25rem 1.5rem;
			font-size: 16px;
			font-weight: 400;
			margin-bottom: 10px;
			min-height: 50px;
			white-space: nowrap;
			width: 100%;

			display: flex;
			align-items: center;

			border: 2px solid $_green-light;
			&:not(.clean) {
				background: #ffffff;

				box-shadow: 2px 8px 16px #1f196014;
			}
			&.wideBox {
				min-width: 220px;
				background: #eeeef2;
				box-shadow: none;
			}
			&.clean {
				padding: 1.25rem 1rem;
			}
			&.hideBox {
				opacity: 0;
				pointer-events: none;
			}
		}

		.midBox {
			min-width: 160px;
		}

		&.flexEnd {
			display: flex;
			justify-content: flex-end;
		}
		&.alignStart {
			display: flex;
			align-items: flex-start;
		}
		&.taxRow {
			padding-bottom: 10px;
		}
		.colsWrapper {
			display: grid;
			grid-template-columns: repeat(2, minmax(0, 1fr));
			min-width: fit-content;
			.col {
				width: 100%;
			}
		}

		.colsWrapperProject{
			grid-template-columns: repeat(2, minmax(0, 1fr));
			min-width: fit-content;
			.col {
				width: 100%;
			}
		}
	}
	.col {
		display: flex;
		flex-direction: column;
		width: fit-content;
		padding-left: 3rem;
		&:first-child {
			padding-left: 1.5rem;
		}
	}

	.colProject{
		display: flex;
		flex-direction: column;
		padding-left: 3rem;
		width: 300px;
		&:first-child {
			padding-left: 1.5rem;
		}
	}

	.solid {
		.box {
			width: 100%;
		}
	}
	.secondCol {
		padding-left: 1.5rem;
		display: flex;
		justify-content: flex-end;
		&.flatCol {
			.box {
				min-width: fit-content;
			}
			& + .col {
				padding-left: 1.5rem;
			}
		}
		&:last-child {
			padding-right: 1.5rem;
		}
		&.hideCol {
			opacity: 0;
			pointer-events: none;
		}
	}
	.bottomSection {
		opacity: 0.5;
		font-size: 16px;
		padding-top: 1rem;
		padding-bottom: 0;
	}
	.totalCell {
		padding-right: 4rem;
	}
}

@media print {
	.payslip {
		.box {
			break-inside: avoid;
		}
	}
}
