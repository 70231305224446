@import "styles/_variables.scss";

$_8px: 0.5rem;
$_10px: 0.625rem;
$_12px: 0.75rem;
$_16px: 1rem;
$_17px: 1.0625rem;
$_18px: 1.125rem;
$_20px: 1.25rem;
$_22px: 1.375rem;
$_24px: 1.5rem;
$_30px: 1.875rem;
$_50px: 3.125rem;
$_90px: 5.625rem;
$_100px: 6.25rem;
$_130px: 8.125rem;
$_202px: 12.625rem;

:root {
  font-size: 16px;
}

.appContent {
  padding: 0 !important;
  font-size: 16px;
}

.calculationLanguageModal {
  height: 20vh;
}

.calculationsComponent {
  padding: 2rem 3rem;
  padding-bottom: 0;
  padding-right: $_30px;
  min-width: fit-content;
  height: calc(100vh - 87.3px - 12px);

  .subTitle {
    font-weight: normal;
    display: flex;
    width: fit-content;
    margin: 0px;
    align-items: flex-start;
    padding-top: 0.625rem;
    font-size: $_18px;
    padding-right: 0.625rem;
    margin-bottom: 1.5rem;

    div {
      padding-left: $_8px;
    }
  }

  .header {
    display: flex;
    flex-wrap: wrap;
    min-width: fit-content;
    white-space: nowrap;

    h1 {
      margin: 0;
      margin-bottom: 5px;
      flex: 1 1 100%;
    }
  }

  .headerButtonsContainer {
    flex: 1 1 50%;
    width: 50%;
    display: flex;
    min-width: fit-content;

    button {
      height: $_50px;
      margin: 0;
      margin-bottom: $_30px;

      &:not(.darkButton) {
        margin-right: 1rem;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .headerBtn {
      max-width: fit-content;
    }

    .darkButton {
      border: 1px solid #1f1960;
      color: #fff;
      background-color: #1f1960;
    }

    .lightButton {
      border: 1px solid #1f1960;
      color: #fff;
      background-color: #1f1960;
    }
  }

  .icon {
    width: 20px;
    height: 20px;
    max-width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .iconContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: $_8px;
    color: $_green-light;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  .emailIcon {
    transform: rotate(-90deg);
    padding-right: 0;
    padding-bottom: 0.5rem;
    display: block;
  }

  .buttonTitle {
    font-weight: 500;
    font-size: $_18px;
    letter-spacing: 0;
  }

  .creatorName {
    color: $_green-light;
  }

  .filterRow {
    display: flex;
    font-size: $_18px;
    margin-bottom: $_30px;
    position: relative;
  }

  .filterDescription {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    color: #261f6399;
    padding-right: 14px;
    font-weight: 500;
  }

  .filterButtons {
    height: 37px;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    font-size: $_18px;
    font-weight: 500;
    min-width: 250px;
    z-index: 1041;

    .button {
      background-color: #fff;
      opacity: 0.8;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 2rem;
      padding-left: 2rem;
      height: 100%;
      cursor: pointer;
      user-select: none;
      flex: 1 1 50%;
      width: 50%;
      min-width: fit-content;

      &:first-of-type {
        border-right: 1px solid $_green-light;
      }

      &:last-of-type {
        padding-right: 0;
        padding-left: 1.5rem;
        justify-content: space-between;
      }
    }

    .calendar {
      display: flex;
      padding: 5px 10px;
      margin-left: 1rem;
      background-color: $_green-light;
      opacity: 0.5;
      height: 100%;
      display: flex;
      align-items: center;
      color: white;
    }
  }

  .filterContent {
    border: 0.5px solid $_green-light;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    position: absolute;
    top: 48px;
    right: 0;
    overflow: hidden;
    min-width: 250px;
    z-index: 1041;
    transition: all 0.3s ease;

    .months,
    .years {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 100%;
      overflow-y: auto;
      width: 50%;
    }

    .months {
      border-right: 1px solid $_green-light;
    }

    .periodItem {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $_17px;
      font-weight: 500;
      opacity: 0.5;
      padding: 0.75rem 1.5rem;
      cursor: pointer;
      user-select: none;

      &.selected,
      &:hover {
        opacity: 0.9;
        background-color: #eff5e6;
      }
    }
  }

  .tableContainer {
    font-size: $_18px;
    height: calc(100vh - 21.5rem);
    min-height: calc(100vh - 21.5rem);

    &.loaderShown {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .headerDataRow {
      th {
        &:first-child {
          font-size: $_24px;
        }

        font-weight: 700;
        font-size: $_22px;
      }
    }

    .checkbox {
      background-color: #89c73226 !important;
      cursor: pointer;
      width: 22px !important;
      height: 22px !important;
      border-width: 1px !important;
      border-color: #1f196099 !important;
    }

    .checkboxCell {
      label {
        display: flex;
        justify-content: center;
        max-width: fit-content;
        margin: auto;
      }

      text-align: center;
      padding: $_10px $_24px;
    }

    .checkboxIconContainer {
      display: flex;
      flex: 1;
      align-self: stretch;
    }

    .contentClass {
      padding-left: 35px;
      padding-bottom: 0px;

      .modalContent {
        padding-right: 30px;
        padding-bottom: 20px;
      }
    }
  }

  .tableMainRow {
    th {
      color: #261f6399;
      font-size: $_16px !important;
    }
  }

  .tableHeader {
    tr {
      &:nth-child(1) {
        th {
          position: sticky;
          top: 0;
          height: 34px;
        }
      }

      &:nth-child(2) {
        th {
          position: sticky;
          top: 34px !important;
          padding-top: 0.3125rem !important;
          padding-bottom: 0.5625rem !important;
          height: 43px !important;
        }
      }

      &:nth-child(3) {
        th {
          position: sticky;
          top: calc(34px + 43px) !important;
        }
      }
    }

    tr:first-of-type {
      th:not(:first-of-type) {
        vertical-align: bottom;
      }

      th {
        padding-bottom: 0;
      }
    }

    th {
      font-size: $_18px;
      padding: $_12px;

      &:not(.numberCell):not(.checkboxCell):not(.noWidth) {
        min-width: fit-content;
        white-space: nowrap;
      }

      &.numberCell {
        min-width: $_130px;
      }

      &.noWrap {
        white-space: nowrap;
      }
    }

    .allTitle {
      font-size: $_18px !important;
    }
  }

  .tableBody {
    tr {
      border-bottom: 2px solid #fafafa;

      &:last-of-type {
        td:first-of-type {
          border-bottom-left-radius: 20px !important;
        }

        td:last-of-type {
          border-bottom-right-radius: 20px !important;
        }
      }
    }

    td {
      font-size: $_22px;
      font-weight: normal;
      padding: $_12px $_16px;
      background-color: white;
    }

    .nameCell {
      min-width: $_202px;
    }

    .f20 {
      font-size: $_20px;
    }
    .unclickable {
      pointer-events: none;
    }

    .f18 {
      font-size: $_18px;
    }
  }

  .tableWrapper {
    height: calc(100vh - 20.5rem);
    min-height: calc(100vh - 20.5rem);
    border-radius: 20px;
  }

  .tableHeaderSubtitle {
    color: $_blue-dark;
    font-size: $_16px !important;
    padding-top: $_8px;
    font-weight: normal;
  }

  .tableTitle {
    font-weight: 500;
    font-size: $_18px;
  }

  .tableSubtitle {
    font-size: $_18px;
    font-weight: normal;
    color: #261f6399;
    padding-top: $_12px;

    &:empty {
      padding-top: 0;
    }
  }

  .inputCell {
    input {
      width: $_90px;
      padding: $_8px;
      border-radius: 0;
      cursor: pointer;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type="number"] {
        -moz-appearance: textfield;
      }

      font-size: $_20px;
    }

    border: 1px solid #1f196099;
    background-color: #89c7321a;
    border-radius: 5px;
    margin: 0;
  }

  th.mainHeaderCell {
    padding: 0.4375rem 1.0625rem 0.25rem;
  }

  .modalHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $_green-light;
    width: 100%;
    font-weight: 500;
    padding-left: 11px;

    .icon {
      margin-left: 1rem;
      cursor: pointer;
      display: flex;

      svg {
        width: 22px;
        height: 22px;
      }
    }
  }

  .headerContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-size: 22px;

    .title {
      white-space: nowrap;
      height: 22px;
    }

    .asterix {
      color: $_red;
    }
  }

  .modalContent {
    .row {
      display: flex;
      justify-content: flex-start;
    }

    .dashedBottom {
      border-bottom: 1px dashed #1f1960;
      padding-bottom: 24px !important;
    }

    .contentRow {
      display: flex;
      padding-top: 18px;
      padding-bottom: 19px;
      padding-right: 44px;

      &:not(:last-child) {
        padding-top: 19px;
      }
    }

    .hoursRow {
      &:first-child {
        padding-left: 10px;
        padding-right: 0;

        .desc {
          font-size: 18px;
        }

        .value {
          font-size: 18px;
          width: 60px;
        }
      }

      &.secondContent {
        padding-right: 60px;
      }
    }

    .secondContent {
      display: flex;
      padding: 11px 30px 11px 10px;

      &:nth-child(2) {
        padding-top: 24px;
      }

      .modalRowTitle {
        flex: 1;
      }

      .equalSign {
        padding-left: 18px;
        padding-right: 18px;
        display: flex;
        align-items: center;
        opacity: 0.5;
        font-size: 18px;
      }
    }

    .modalInput {
      border: 1px solid #1f196099;
      background-color: #89c7321a;
      border-radius: 5px;
      margin: 0;
      width: 100px;
      font-size: $_22px;
      height: 42px;

      input {
        width: 100%;
        padding: $_8px;
        border-radius: 0;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &[type="number"] {
          -moz-appearance: textfield;
        }
      }
    }

    padding-bottom: 56px;

    &.editMode {
      padding-bottom: 0px;

      .modalInput:not(.static) {
        background-color: #fff;
      }
    }
  }

  .sendEmailButton {
    margin-left: 2rem;
    padding: 0.5rem 1.5rem;

    &:last-child {
      margin-right: 2rem;
    }
  }

  .modalRow {
    padding: 0;
    font-weight: bold;
    display: flex;
    flex-direction: column;

    &:first-of-type {
      padding-top: 30px;
    }

    &.brutoHoursRow {
      padding-top: 30px;
    }

    padding-bottom: 18px !important;

    .contentRow {
      &:first-of-type {
        padding-top: 0px;
      }
    }
  }

  .hoursContent {
    padding-bottom: 11px !important;
  }

  .hoursRow {
    display: flex;
    align-items: center;

    &:first-of-type {
      display: flex;
      justify-content: flex-start;

      .modalRowTitle {
        padding-right: 80px;
      }

      .modalInput {
        margin-right: 10px;
      }
    }

    .modalInput {
      width: 105px;
    }

    .modalRowTitle {
      padding-right: 0;
      height: fit-content;
    }

    .modalRowSubtitle {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      padding-right: 14px;
      opacity: 0.5;
      font-size: 18px;
    }
  }

  .additionsRow {
    padding: 30px 100px 30px 0;
    min-width: 500px;

    .contentRow {
      padding-right: 150px;
    }

    .modalRowTitle {
      padding-right: 0;
      flex: 1;
    }
  }

  .modalRowTitle {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-right: 134px;
    font-size: 18px;
  }

  .w500 {
    padding: 30px 0;
    min-width: 500px;
  }

  .rowDescription {
    display: flex;
    align-items: center;
    font-size: 18px;

    .desc {
      padding-left: 20px;
      padding-right: 10px;
      color: #261f63;
      opacity: 0.5;
    }
  }
}

@media screen and (max-width: 1450px) {
  :root {
    font-size: 12px;
  }

  .calculationsComponent {
    padding-left: 15px;
    padding-right: 15px;

    .tableWrapper {
      height: calc(100vh - 24.5rem);
      min-height: calc(100vh - 24.5rem);
    }

    .tableHeader {
      tr {
        &:nth-child(1) {
          th {
            position: sticky;
            top: 0;
            height: 25px;
          }
        }

        &:nth-child(2) {
          th {
            position: sticky;
            top: 25px !important;
            padding-top: 0.3125rem !important;
            padding-bottom: 0.5625rem !important;
            height: 32.25px !important;
          }
        }

        &:nth-child(3) {
          th {
            position: sticky;
            top: calc(25px + 32.25px) !important;
          }
        }
      }
    }
  }

  .tableContainer {
    font-size: $_18px;
    height: calc(100vh - 23.5rem - 9px) !important;
    min-height: calc(100vh - 23.5rem - 9px) !important;
  }

  .tableBody {
    tr {
      border-bottom: 2px solid #fafafa;
    }

    td {
      font-size: $_22px;
      font-weight: normal;
      background-color: white;

      &:not(.nameCell) {
        padding: 8px !important;
      }
    }

    .nameCell {
      min-width: 175px;
    }

    .tableSubtitle {
      padding-top: 7px;
    }
  }
}

.lockContainer {
  margin-left: 18px;
  color: #89c732;
  height: 20px;
  cursor: pointer;
}

.codeContainer {
  font-size: 20px;
  font-family: "Roboto";
  font-weight: 500;
  color: #89c732;
}

.codeWrapper {
  display: flex;
  margin-top: 26px;

  .titleClass {
    margin-top: 10px;
    font-weight: 500;
    padding-right: 25px;
  }

  .modalInput {
    border: 1px solid #1f196099;
    background-color: #89c7321a;
    border-radius: 5px;
    margin: 0;
    width: 150px;
    font-size: $_22px;
    height: 42px;

    input {
      width: 100%;
      padding: $_8px;
      border-radius: 0;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      &[type="number"] {
        -moz-appearance: textfield;
      }
    }
  }
}

.headerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  min-width: 140px;
  &.loading {
    opacity: 0.7;
    pointer-events: none;
  }

  font-weight: 500;

  &:first-child svg {
    stroke: $_green-light;
  }

  &.employeesModalButton svg {
    fill: $_green-light;
  }

  &:last-child {
    margin-right: 0;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  .buttonTitle {
    font-weight: 500;
    font-size: 18px;
  }
}

.hasHealthInsurance {
  color: red;
  display: flex;
  align-content: flex-start;
  align-items: center;
}

.regularHoursContainer {
  position: relative;
  & + .equalSign {
    padding: 0 18px 0 8px;
  }
  .rowDescription {
    position: absolute;
    top: 110%;
    .modalInput {
      margin-right: 0 !important;
    }
    .desc,
    .value {
      padding: 0;
      font-size: 10px !important;
      line-height: 10px;
    }
    .desc {
      width: 70%;
    }
    .value {
      width: 30%;
      font-size: 14px !important;
    }
  }
}
