@import "styles/_variables.scss";
$_8px: 0.5rem;
$_10px: 0.625rem;
$_12px: 0.75rem;
$_16px: 1rem;
$_17px: 1.0625rem;
$_18px: 1.125rem;
$_20px: 1.25rem;
$_22px: 1.375rem;
$_24px: 1.5rem;
$_30px: 1.875rem;
$_50px: 3.125rem;
$_90px: 5.625rem;
$_100px: 6.25rem;
$_130px: 8.125rem;
$_202px: 12.625rem;

.header {
  display: flex;
  margin-top: 33px;

  .arrowIcon {
    margin-top: 7px;
    margin-right: 30px;
    width: 19px;
    height: 19px;
    color: #89c732;
    cursor: pointer;
  }
}

.totalCell {
  padding: 1.25rem;

  div {
    width: fit-content;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #1f196099;

    background-color: rgba(137, 199, 50, 0.10196078431372549);
    text-align: center;
  }
}

.reportsInner {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 50px;
}

.titleHeader {
  display: flex;
  margin-bottom: 40px;

  .orderPaymentHeader {
    margin-top: 6px;
    font-size: 28px;
    color: #1f1960;
    font-weight: 500;
    white-space: nowrap;
    padding-right: 3.5rem;
  }

  .point {
    margin-right: 32px;
    margin-top: 15px;
    border-radius: 50%;
    background-color: #89c732;
    width: 15px;
    height: 15px;
  }

  .downloadIcon {
    color: #89c732;
    padding-right: 10px;
    margin-left: 30px;

    svg {
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }

  .downloadText {
    font-size: 20px;
    color: #1f1960;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    display: flex;
    align-items: flex-end;

    .dropdownText {
      margin-left: 2px;
      margin-top: -2px;
      font-weight: 500;
      padding-left: 10px;
      display: flex;
      align-items: center;
    }

    .printText {
      padding-left: 10px;
    }
  }

  .sendEmailIcon {
    color: #89c732;
    display: flex;
    align-items: center;

    margin-left: 30px;
    svg {
      transform: rotate(-90deg);
      width: 30px;
      height: 30px;
      cursor: pointer;
      margin-right: 10px;
    }
  }

  .printIcon {
    stroke: #89c732 !important;
    display: flex;
    align-items: center;

    svg {
      margin-left: 30px;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }
  }

  .headerDate {
    font-size: 18px;
    font-weight: 500;
    color: #aeacc4;
    margin-left: 30px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: flex-end;
  }

  .iconText {
    display: flex;
    align-items: center;
  }
}

.filterText {
  display: flex;

  .sectorName {
    margin-top: 33px;
    font-size: 18px;
    font-weight: 500;
    color: #1f1960;
  }

  .bankName {
    margin-top: 33px;
    font-size: 18px;
    font-weight: 500;
    color: #1f1960;
    margin-left: 292px;
  }
}

.filterBox {
  margin-top: 15px;
  display: flex;
  margin-bottom: 20px;

  .sectorBoxContainer {
    width: 264px;
    height: 42px;
    padding: 6px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #c1e095;
    border-radius: 8px;
    min-width: 140px;
    background-color: #fbfbfb;
    font-weight: 500;
    cursor: pointer;
  }

  .bankBoxContainer {
    margin-left: 82px;
    width: 264px;
    height: 42px;
    padding: 6px 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid #c1e095;
    border-radius: 8px;
    min-width: 140px;
    background-color: #fbfbfb;
    font-weight: 500;
    cursor: pointer;
  }
}

.tableContainer {
  margin-top: 31px;

  thead th {
    border-radius: 0px !important;
  }

  .tableBody {
    .tableRow {
      .bodyCell {
        padding: 0.75rem 1.25rem;
        font-weight: 400;
        padding: 25px;

        &.bold {
          font-weight: 600;
        }
        &.iconCell {
          svg {
            color: $_blue-dark;
            width: 25px;
            height: 25px;
          }
          padding-left: 3rem;
        }

        .sectorType {
          margin-top: 12px;
          color: #261f6399;
          font-size: 18px;
          font-weight: 500;
        }
      }
      &:not(:last-child) {
        border-bottom: 2px solid #f0f0f0;
      }

      .text {
        font-size: 19px;
        font-weight: 500;
        max-width: 250px;
      }
    }

    .userNameCell {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      img,
      .image {
        width: 50px;
        min-width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      .nameContainer {
        margin-left: 1rem;
        cursor: pointer;
      }
    }
  }
}

.filterRow {
  display: flex;
  font-size: $_18px;
  margin-bottom: $_30px;
  position: relative;
  flex: 1;
  justify-content: flex-end;
  color: $_blue-dark;
}

.filterDescription {
  display: flex;
  justify-content: flex-end;
  color: #261f6399;
  padding-right: 14px;
}

.filterButtons {
  height: 37px;
  display: flex;
  border-radius: 10px;
  overflow: hidden;
  font-size: $_18px;
  font-weight: 500;
  min-width: 250px;
  z-index: 1041;

  .button {
    background-color: #fff;
    opacity: 0.8;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 2rem;
    padding-left: 2rem;
    height: 100%;
    cursor: pointer;
    user-select: none;
    flex: 1 1 50%;
    width: 50%;
    min-width: fit-content;
    &:first-of-type {
      border-right: 1px solid $_green-light;
    }
    &:last-of-type {
      padding-right: 0;
      padding-left: 1.5rem;
      justify-content: space-between;
    }
  }
  .calendar {
    display: flex;
    padding: 5px 10px;
    margin-left: 1rem;
    background-color: $_green-light;
    opacity: 0.5;
    height: 100%;
    display: flex;
    align-items: center;
    color: white;
  }
}

.filterContent {
  border: 0.5px solid $_green-light;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  position: absolute;
  top: 3rem;
  right: 0;
  height: 55vh;
  overflow: hidden;
  min-width: 125px;
  z-index: 1041;

  .months,
  .years {
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100%;
    overflow-y: auto;
    width: 100%;
  }
  .months {
    border-right: 1px solid $_green-light;
  }
  .periodItem {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $_17px;
    font-weight: 500;
    opacity: 0.5;
    padding: 0.75rem 1.5rem;
    cursor: pointer;
    user-select: none;
    &.selected,
    &:hover {
      opacity: 0.9;
      background-color: #eff5e6;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 100px;
  height: 100%;
}

.checkboxTemplate {
  display: flex;
  padding-left: 20px;
}

.checkbox {
  background-color: #89c73226 !important;
  cursor: pointer;
  margin-right: 10px;
  width: 20px !important;
  height: 20px !important;
  border-width: 1px !important;
  border-color: #1f196099 !important;

  label {
    justify-content: center;
  }
}

.checkboxCell {
  label {
    display: flex;
    justify-content: center;
    max-width: fit-content;
    margin: auto;
  }
  text-align: center;
  padding: 10px 24px;
}

.checkboxIconContainer {
  display: flex;
  flex: 1;
  align-self: stretch;
}

.checkboxes {
  left: -100px;
}

.isCheckBoxActive {
  overflow: hidden;
  animation: widthTransition forwards 150ms;
  label {
    opacity: 0;
    animation: fadeIn forwards 150ms;
    animation-delay: 100ms;
  }
}

.checkboxIcon {
  display: flex;
  justify-content: center;
  margin-left: 15px;
}

.allCheckboxes {
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  padding: 10px 15px;

  .allCheckboxesText {
    margin-left: 20px;
  }
}
