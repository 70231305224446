@import "styles/_variables.scss";

.container {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    color: $_blue-dark;
    background-color: #89c73233;
    border-radius: 10px;
    .icon { 
        width: 20px;
        height: 20px;
    }
    input {
        border: none;
        background-color: transparent;
        color: inherit;
        font-size: 17px;
        padding-left: 15px;

        outline: none;
        font-weight: 500;
        height: 100%;
        font-family: inherit;

        &::placeholder {
            color: inherit;
        }
        &::-webkit-search-cancel-button {
            -webkit-appearance: none;
            appearance: none;
        }
    }

}