@import "styles/_variables.scss";

.template{
  display: flex;
  margin-bottom: 31px;

  &:last-child{
    margin-right: 0;
  }

  .templateBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-shadow: 2px 8px 16px #18327314;
    background-color: #ffffff;
    border-radius: 7px;
    margin-right: 60px;
    cursor: pointer;

    .folderIcon{
      width: 30px;
      height: 28px;
      margin-top: 5px;
    }

    .templateTitle{
      color: $_blue-dark;
      font-family: "Roboto";
      font-weight: 700;
      margin-left: 10px;
      font-size: 20px;
      color: #261F63;
    }
  }
}

.firstBox{
  display: flex;
  align-items: center;
  width: 100%;
  padding: 7px 20px 7px 0px;
  vertical-align: center;
}

.secondBox{
  display: flex;
  align-items: center;
  padding:0 20px;
}
.checkboxTemplate{
  display: flex;
  padding-left: 20px;
;
}

.checkbox {
  background-color: #89c73226 !important;
  cursor: pointer;
  margin-right: 10px;
  width: 20px !important;
  height: 20px !important;
  border-width: 1px !important;
  border-color: #1f196099 !important;
}

.checkboxCell {
  label {
    display: flex;
    justify-content: center;
    max-width: fit-content;
    margin: auto;
  }
  text-align: center;
  padding: 10px 24px;
}

.checkboxIconContainer {
  display: flex;
  flex: 1;
  align-self: stretch;
}

.checkboxes{
  left: -100px;
}
.isCheckBoxActive{
  overflow: hidden;
  animation: widthTransition forwards 150ms;
  label{
    opacity: 0;
    animation: fadeIn forwards 150ms;
    animation-delay: 100ms;
  }
}

@keyframes widthTransition {
  0%{
    width: 0px;
  }
  100%{
    width: 30px;
  }
}

@keyframes fadeIn {
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.templateName{
  .uploadIcon{
    transform: rotate(90deg);
    margin-right: 10px;
  }
  .textUpload{
    color: $_blue-dark;
    font-size: 20px;
    font-weight: 500;
  }
}

.rowInput {
  margin: 0 0 0 10px ;
  &:read-only,
  input:read-only {
    border-bottom: 1px solid transparent;
    cursor: pointer;
    color: $_blue-dark;
    font-family: "Roboto";
    font-weight: 700;
    margin-left: 10px;
    font-size: 18px;
  }

  input::placeholder{
    font-weight: 500;
    font-size: 20px;
    color:$_blue-dark;
  }
}

.actions{
  display: flex;
  align-items: center;
  padding: 10px 10px 30px 10px;

}

.iconsBox{
  display: flex;
  align-items: center;
  margin-right: 40px;
  cursor: pointer;

  .icons{
    color:#89c732;
    width: 23px;
    height: 23px;
  }

  .uploadIcon{
    color:#89c732;
    width: 23px;
    height: 23px;
    transform: rotate(90deg);
  }

  span{
    margin-left: 10px;
    font-size: 18px;
    font-family: Roboto;
    color: $_blue-dark;
    font-weight: 500;
  }
}

.iconsBoxDeactivated{
  display: flex;
  align-items: center;
  margin-right: 40px;
  cursor: pointer;
  opacity: 0.4;

  .icons{
    color:#89c732;
    width: 23px;
    height: 23px;
  }

  .uploadIcon{
    color:#89c732;
    width: 23px;
    height: 23px;
    transform: rotate(90deg);
  }

  span{
    margin-left: 10px;
    font-size: 18px;
    font-family: Roboto;
    color: $_blue-dark;
    font-weight: 500;
  }
}

.emailBox{
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 20px;

  input{
    width: 100px;
    background-color: #89C7321A;
    border: 1px solid #1F196099;
    padding: 0px 10px;
    height: 42px;
    border-radius: 5px;
  }
}

.validateEmail{
  padding-left: 10px;
}

.rowInputEmail{
  margin-left: 10px;
}

.fileInputContainer{
  position: relative;
  input{
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
  }
}
.selectedFileName{
  font-size: 14px;
  width: 100%;
  overflow: hidden;
  color: inherit;
  padding: 0 10px;
  margin: 0;
}

.editIcons{
  display: flex;
  align-items: center;
}

@media screen and (max-width: 900px) {
  .template{
    min-width: 50%;
  }
}

@media screen and (max-width: 1024px) {
  .template{
    min-width: 99%;
  }
}