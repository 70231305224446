.reportsComponent{
    .header{
        margin-top: 33px;
    }

    .reportsInner{
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        margin-top: 50px;
    }
}